.ValueItem {
    .about-content {
        margin-bottom: .1rem;
    }
}

.WealthItem {
    width: 5.2rem;
    height: 2.6rem;
    padding: .39rem .37rem 0;
    margin-bottom: .85rem;

    position: relative;
    background-color: #fff;
    box-shadow: 0rem 0rem .24rem -0.08rem #E3E3E3;
    border-radius: .15rem;
    opacity: 0.94;
    transition: background-color 0.3s, color 0.3s;

    .item-icon {
        position: absolute;
        right: 0rem;
        bottom: 0rem;
        z-index: -1;
        width: 1.62rem;
        height: 1.62rem;
    }

    .item-title {
        margin-bottom: .19rem;
        font-weight: bold;
        z-index: 2;
        position: absolute;
        font-size: .36rem;
        letter-spacing: .0129rem;
        line-height: .48rem;
    }

    .item-content{
        margin-top: .52rem;
    }

    .background{
        width: 1.8rem;
        height: .2rem;
        background-color: rgba($color: #DED2CD, $alpha: .5);
        border-radius: .18rem;
        position: absolute;
        top: .65rem;
        left: .27rem;
        z-index: 1;
    }

    &:hover {
        background-color: #002E53;
        color: white;

        .item-header>.img {
            border-color: #fff;
        }
        .background{
            background-color: rgba($color: #F8F6F5, $alpha: .1);
        }
    }
}

.TeamItem {
    width: 3.2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: rgba(0, 46, 83, 0.5);
    margin: 0 .05rem;
    padding-bottom: .56rem;
    height: 5.2rem;
    transition: height .5s, width .2s;
    color: white;
    position: relative;

    .circle {
        width: 1.42rem;
        height: 1.42rem;
        margin: .48rem 0 .1rem 0;
        border-radius: .71rem;
        background: #D8D8D8;
    }

    .sub-line {
        width: 1.28rem;
        height: .03rem;
        margin: .09rem auto;
        border-radius: .015rem;
        background-color: #A68576;
    }

    .job {
        margin-bottom: .48rem;
        color: white;
    }

    .about-desc {
        margin-bottom: .1rem;
        display: none;
        padding: 0 .35rem;
    }

    .arrow {
        width: .7rem;
        height: .7rem;
        position: absolute;
        display: block;
        bottom: .7rem;
    }

    &:hover {
        background-color: rgba(0, 46, 83, 1);
        height: 6rem;
        width: 5.02rem;
        .about-desc-group{
            overflow: auto;
            &::-webkit-scrollbar {
                display: none; /* Chrome Safari */
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
        }
        .about-desc {
            display: block;
        }

        .arrow {
            display: none;
        }
    }
    
}

.NewsItem {
    display: flex;
    padding-top: .58rem;
    .img-box{

        width: 6.8rem;
        height: 3.4rem;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }


    .news-container {
        // padding-left: .68rem;
        margin-left: .68rem;
        // flex-direction: column;
        position: relative;
        max-width: 5.74rem;

        .news-title {
            margin-bottom: .17rem;
            cursor: pointer;
            min-width: 1.15rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .news-content{
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }

        .news-time {
            position: absolute;
            left: 0rem;
            bottom: 0;
            color: rgba(173, 173, 173, 1);
        }
    }
}