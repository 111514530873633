.CustomNews{
    padding: 0 2.625rem;
    .item-banner{
        width: 100%;
        height: 9.875rem;
        background-color: #fafafa;
        margin: 0 auto;
    }
    .servers-banner{
        font-size: 80px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 112px;
        letter-spacing: 2.86px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .item-content{
        margin-top: 96px;
        color: #1F1E2E;
        .content{
            font-size: 18px;
            letter-spacing: 0.64px;
            p{
                margin: 20px 0;
            }
        }
        h1,h2,h3,h4,h5,h6{
            font-weight: bold;
        }
        .title{
            font-size: .75rem;
            line-height: 1.15rem;
            font-weight: 600;
            letter-spacing: 1.5px;
            text-align: center;
        }
        .mb93{
            margin-bottom: 93px;
        }
        .date{
            margin-top: 22px;
            text-align: center;
            line-height: 25px;
        }
        .txt{
            margin-top: 46px;
            line-height: 30px;
        }
    }
}