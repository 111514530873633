.News{
    padding: .5rem 0 1.45rem;
    .content{
        width: 11rem;
        margin: 0 auto;
        .CustomNews{
            padding: 0;
            .item-banner{
                height: 5.5rem;
            }
            .item-content{
                .title{
                    font-size: .42rem;
                }
            }
        }
    }
}

@mixin contentFont {
    font-size: .75rem;
    line-height: 1.0625rem;
    letter-spacing: .0269rem;
}
@mixin contentSubtitle{
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: .04rem;
}

.NewsIpad{
    padding: 0 2.625rem;
    .content{
        width: 100%;
        .CustomNews{
            .item-banner{
                margin-top: 1.75rem;
                height: 9.875rem;

            }
            .item-content{
                .title{
                    font-size: .75rem;
                }
            }
        }
    }
}

.NewsMobile{
    padding: 4.0625rem 0;
    .content{
        width: 20.625rem;
        margin: 0 auto;
        .CustomNews{
            .item-banner{
                height: 10.375rem;
            }
            .item-content{
                margin-top: 2rem;
                .title{
                    @include contentSubtitle();
                }
                .content{
                    margin-top: 2rem;
                    @include contentFont();
                }
            }
        }
    }
}