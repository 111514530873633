@mixin contentFont {
    font-size: .75rem;
    line-height: 1.0625rem;
    letter-spacing: .0269rem;
}
@mixin contentSubtitle{
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: .04rem;
}

.details{
    padding-bottom: 200px;
    margin-top: 1.15rem;
    .details-banner{
        width: 10.98rem;
        height: 5.49rem;
        margin: .27rem auto .96rem;
        background-color: #fafafa;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .policy{
        height: 0;
    }
    .details-content{
        width: 10.98rem;
        margin: 0 auto;
        .content-title{
            text-align: center;
            margin-bottom: .53rem;
            h5{
                font-size: .42rem;
                line-height: .59rem;
                letter-spacing: .015rem;
            }
        }
        .content-desc{
            font-size: .18rem;
            color: #666;
            letter-spacing: .0064rem;
            line-height: .3rem;
            font-weight: 400;
            >p{
                margin-bottom: .3rem;
            }
        }
    }
    
    .Asset-manager,.Discretionary_Mandates,.Investment_Advisory_Mandates,.HK_Market,.Alternative_Investment_Funds,
    .AMC,.Global_Market,.Futures_Options,.Structured_Products,.Other_Investment_Services,.education,.Wealth_Inheritance,.Investment_Philosophy{
        width: 11.27rem;
        margin: 0 auto;
        font-size: .2rem;
        letter-spacing: .0071rem;
        .detail-title{
            font-size: .42rem;
            font-weight: bold;
            font-family: SourceHanSansCN-Bold;
            text-align: center;
            margin: 1.35rem 0 .78rem;
        }
        .detail-subtitle{
            margin-bottom: .9rem;
            .subtitle-item{
                img{
                    width: .2rem;
                    height: .2rem;
                    margin-top: -0.05rem;
                    margin-right: .08rem;
                }
                span{
                    line-height: .5rem;
                }
            }
        }
        .detail-content{
            .content-item{
                display: flex;
                margin-bottom: 1.08rem;
                .item-left{
                    width: 5rem;
                    .item-title{
                        font-size: .25rem;
                        color: #A68576;
                        line-height: .42rem;
                        letter-spacing: .0089rem;
                    }
                    .item-content{
                        font-size: .18rem;
                        line-height: .28rem;
                        letter-spacing: .0064rem;
                    }
                    img{
                        width: .49rem;
                        height: .22rem;
                    }
                }
                .item-right{
                    width: 5rem;
                    height: 2rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .m130{
                    margin-right: 1.3rem;
                }
            }
        }
        .detail-footer{
            margin-bottom: .5rem;
            p{
                line-height: .42rem;
            }
        }
    }
    .Discretionary_Mandates{
        .detail-subtitle{
            margin-bottom: 0;
            .subtitle-desc{
                margin: 0.12rem 0;
                color: #666666;
                padding-left: 0.3rem;
                line-height: .3rem;
                font-size: .18rem;
            }
        }
        .detail-desc{
            margin-top: 0.12rem;
            padding-left: 0.30rem;
            color: #666666;
            .desc-item{
                line-height: .3rem;
                margin-bottom: .12rem;
                font-size: .18rem;
                &:nth-child(4){
                    font-size: .2rem;
                    color: #1F1E2E;
                }
            }
        }
        .detail-content{
            margin-top: .9rem;
            .content-title{
                margin-bottom: 1rem;
                font-size: .3rem;
                line-height: .45rem;
            }
            .content-item{
                .item-content{
                    margin-bottom: .32rem;
                    color: #666666;
                }
                .item-right{
                    height: 2.4rem;
                }
            }
        }
    }
    .Investment_Advisory_Mandates{
        .detail-title{
            font-size: .42rem;
            font-weight: bold;
            font-family: SourceHanSansCN-Bold;
            text-align: center;
            margin: 1.35rem 0 .78rem;
        }
        .detail-content{
            .content-item{
                line-height: .3rem;
                margin-bottom: .12rem;
                font-size: .18rem;
                color: #666;
            }
            .content-image{
                width: 10rem;
                height: 4.5rem;
                margin: 0 auto;
                margin-top: .3rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .HK_Market{
        .detail-subtitle{
            margin-bottom: 0;
        }
        .detail-content{
            .content-item{
                display: block;
                margin-bottom: 0;
                .item-img{
                    width: 10rem;
                    height: 4.5rem;
                    margin: .3rem auto .6rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .item-text{
                    color: #666666;
                    line-height: .5rem;
                }
            }
            .content-footer{
                .item-text{
                    color: #A68576;
                }
            }
        }
    }
    .AMC{
        .detail-subtitle{
            .subtitle-text{
                line-height: .3rem;
                color: #666666;
            }
        }
        .detail-content{
            .content-item{
                display: block;
                .item-img{
                    width: 3.62rem;
                    height: 3.62rem;
                    margin-right: .84rem;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
                .item-text{
                    width: 6.13rem;
                    padding-top: .63rem;
                    .text{
                        line-height: .30rem;
                        color: #666666;
                    }
                }
                .content-title{
                    .title{
                        font-size: .25rem;
                        line-height: .5rem;
                        color: #A68576;
                    }
                    .subtitle{
                        font-size: .2rem;
                        line-height: .36rem;
                        color: #666;
                    }
                }
                .content{
                    .content-list{
                        list-style: none;
                        margin-top: .3rem;
                        li{
                            font-size: .18rem;
                            line-height: .36rem;
                            color: #666666;
                            display: flex;
                            align-items: center;
                            .round{
                                width: .14rem;
                                height: .14rem;
                                border-radius: 50%;
                                background-color: #A68576;
                                margin-right: .08rem;
                            }
                        }
                    }
                }
            }
            .content-img{
                padding-left: .46rem;
                display: flex;
            }
        }
    }
    .Global_Market{
        .detail-content{
            .content-item{
                margin: 0;
                img{
                    width: 10rem;
                    height: 4.5rem;
                    margin: .3rem auto .6rem;
                }
                .item-text{
                    font-size: .2rem;
                    line-height: .5rem;
                    color: #666666;
                }
            }
        }
    }
    .Futures_Options{
        .detail-content{
            .content-title{
                font-size: .25rem;
                line-height: .45rem;
                color: #A68576;
                margin-bottom: .3rem;
                &:nth-of-type(2){
                    margin-top: 1rem;
                }
            }
            .content{
                .text{
                    font-size: .2rem;
                    line-height: .3rem;
                    color: #555;
                }
                .m20{
                    margin: .2rem 0;
                }
                .img{
                    width: 10rem;
                    height: 4.5rem;
                    margin: .3rem auto .6rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .content-list{
                    list-style: none;
                    margin-top: .3rem;
                    margin-bottom: .22rem;
                    li{
                        font-size: .18rem;
                        line-height: .36rem;
                        color: #666666;
                        display: flex;
                        align-items: center;
                        .round{
                            width: .14rem;
                            height: .14rem;
                            border-radius: 50%;
                            background-color: #A68576;
                            margin-right: .08rem;
                        }
                    }
                }
            }
        }
    }
    .Structured_Products{
        .detail-subtitle{
            margin-bottom: .4rem;
            p{
                font-size: .25rem;
                line-height: .45rem;
                color: #A68576;
            }
        }
        .detail-content{
            .content-list{
                list-style: none;
                margin-top: .3rem;
                margin-bottom: .22rem;
                li{
                    font-size: .18rem;
                    line-height: .36rem;
                    color: #666666;
                    display: flex;
                    align-items: center;
                    .round{
                        width: .14rem;
                        height: .14rem;
                        border-radius: 50%;
                        background-color: #A68576;
                        margin-right: .08rem;
                    }
                }
            }
        }
    }
    .Fixed_Income{
        .text{
            margin-bottom: .25rem;
            color: #555;
        }
    }
    .Other_Investment_Services{
        .detail-content{
            .img{
                width: 10rem;
                height: 4.5rem;
                margin: .3rem auto .6rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .text{
                font-size: .2rem;
                line-height: .28rem;
                color: #666666;
                margin-bottom: .1rem;
            }
            .item{
                display: flex;
            }
            .round{
                width: .18rem;
                height: .18rem;
                border-radius: 50%;
                background-color: #A68576;
                margin-top: .05rem;
                margin-right: .1rem;
            }
        }
    }
    .Alternative_Investment_Funds{
        .detail-content{
            .item{
                display: flex;
                margin-bottom: .3rem;
                .img{
                    width: .49rem;
                    height: .22rem;
                    margin-right: .1rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .text{
                    width: calc(100% - .59rem);
                    font-size: .2rem;
                    line-height: .28rem;
                    letter-spacing: .0083rem;
                    color: #666666;
                }
            }
        }
    }
    .MUTUAL_FUND, .education{
        .detail-content{
            .content-item{
                display: block;
                margin-bottom: .6rem;
                .item-title{
                    font-size: .4rem;
                    line-height: .6rem;
                    letter-spacing: .0104rem;
                    color: #A68576;
                    margin-bottom: .2rem;
                }
                .item-content{
                    font-size: .2rem;
                    line-height: .28rem;
                    letter-spacing: .0083rem;
                    color: #666666;
                    .content-li{
                        margin-bottom: .2rem;
                    }
                    .content-img{
                        width: 100%;
                        height: 7.14rem;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .content-item{
                        margin-bottom: .3rem;
                        .content-title{
                            font-size: .24rem;
                            margin-bottom: .23rem;
                            font-weight: 500;
                            color: #555;
                            margin-top: .6rem;
                        }
                        .item-title{
                            color: #555;
                            font-size: .24rem;
                            margin-bottom: .1rem;
                        }
                        .item-desc{
                            font-size: .2rem;
                        }
                    }
                }
                .item-table{
                    >div{
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: .23rem;
                        .content-item{
                            width: 50%;
                            margin-bottom: 0;
                            border: .01rem solid #F5F5F5;
                            .content-item-title{
                                color: rgba($color: #000000, $alpha: .88);
                                background-color: #F5F5F5;
                                height: .5rem;
                                line-height: .5rem;
                                padding-left: .22rem;
                            }
                            .content-item-desc{
                                font-size: .18rem;
                                padding: .12rem .58rem .62rem .29rem;
                            }
                        }
                    }
                }
                .item-consideration{
                    >div{
                        flex-wrap: nowrap;
                        .content-item{
                            flex: 1;
                            .content-item-desc{
                                padding: 0;
                                padding-left: .29rem;
                                padding-top: .05rem;
                                &:last-child{
                                    padding-bottom: .62rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .education{
        background-color: transparent;
        .detail-content{
            .content-item{
                .scene-content{
                    .content-list{
                        display: flex;
                        // height: 1.86rem;
                        border: .08rem solid #F5F5F5;
                        margin-bottom: .11rem;
                        .content-li-title{
                            flex: 1;
                            background-color: #F5F5F5;
                            line-height: 1.86rem;
                            text-align: center;
                            color: rgba($color: #000000, $alpha: .88);
                            position: relative;
                            >p{
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 45%;
                                line-height: .28rem;
                            }
                        }
                        .content-li-desc{
                            flex: 3;
                            padding: .37rem .35rem;
                            font-size: .18rem;
                        }
                    }
                }
                .bcwhite{
                    height: 1.2rem;
                    border: .08rem solid #F5F5F5;
                    background-color: white;
                    margin-bottom: .2rem;
                    padding-top: .1rem;
                    padding-left: .11rem;
                    font-size: .18rem;
                }
                .option-img{
                    margin-top: .66rem;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .option-imglist{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: 1.76rem;
                    background-color: #F5F5F5;
                    .option-imgli{
                        width: 49%;
                        height: auto;
                        margin-bottom: .33rem;
                        background-color: white;
                        &:nth-child(3){
                            margin-bottom: 0;
                        }
                        &:nth-child(4){
                            margin-bottom: 0;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .type{
                    display: flex;
                    .type-item{
                        flex: 1;
                        .type-title{
                            background-color: #F5F5F5;
                            height: .5rem;
                            line-height: .5rem;
                            padding-left: .22rem;
                            color: rgba($color: #000000, $alpha: .88);
                        }
                        .type-desc{
                            .desc{
                                border: .01rem solid #F5F5F5;
                                height: 1.5rem;
                                padding-left: .29rem;
                                padding-top: .2rem;
                            }
                        }
                    }
                }
                .option-example{
                    .content-list{
                        // height: 2.8rem;
                        .content-li-title{
                            position: relative;
                            >p{
                                line-height: .28rem;
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 45%;
                            }
                        }
                        .content-li-desc{
                            flex: 3;
                            padding: .37rem .35rem;
                            p{
                               margin-bottom: .2rem;

                            }
                        }
                    }
                }
                .fixedType{
                    .content-list{
                        // height: 1.1rem;
                        .content-li-title{
                            position: relative;
                            >p{
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 45%;
                                line-height: .28rem;
                            }
                        }
                        .content-li-desc{

                        }
                    }
                }
                .fixedRisk{
                    .content-list{
                        .content-li-title{
                            position: relative;
                            >p{
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 45%;
                                line-height: .28rem;
                            }
                        }
                    }
                }
                .fixedScene{
                    .content-item{
                        .item-desc{
                            margin: .3rem 0;
                        }
                        .item-img{
                            margin-top: .3rem;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .Wealth_Inheritance{
        width: 11.4rem;
        .topInfo{
            background-color: rgba($color: #000000, $alpha: .6);
            width: 6rem;
            color: white;
            padding: .55rem .43rem .5rem .36rem;
            position: absolute;
            top: 2.6rem;
            left: 7.3rem;
            .top-name{
                font-size: .4rem;
                font-family: SourceHanSansCN-Medium;
                margin-bottom: .11rem;
                letter-spacing: .0214rem;
                line-height: .6rem;
            }
            .top-desc{
                font-size: .2rem;
                font-family: SourceHanSansCN-Medium;
                letter-spacing: .0067rem;
                line-height: .28rem;
            }
        }
        .detail-content{
            .detail-desc{
                .desc-title{
                    img{
                        width: .37rem;
                    }
                    .title-text{
                        font-size: .25rem;
                        line-height: .38rem;
                        font-weight: bold;
                        color: #A68576;
                        margin-top: .07rem;
                        margin-bottom: .12rem;
                    }
                }
                .desc-info{
                    .info-text{
                        font-size: .18rem;
                        line-height: .28rem;
                        color: #666666;
                        margin-bottom: .2rem;
                    }
                }
            }
            .family-Structure{
                width: 10rem;
                height: 5.92rem;
                margin: .8rem auto;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .Family-Advantage{
                width: 7.74rem;
                height: 6.66rem;
                margin: 0 auto 1.14rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .advantage-content{
                .desc-title{
                    img{
                        width: .37rem;
                    }
                    .title-text{
                        font-size: .25rem;
                        line-height: .38rem;
                        font-weight: bold;
                        color: #A68576;
                        margin-top: .07rem;
                        margin-bottom: .3rem;
                    }
                }
                .desc-content{
                    .list-box{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                    .AssetManagementItem{
                        width: 5.6rem;
                        height: 2rem;
                        border-radius: .2rem;
                        display: inline-block;
                        margin-right: 0;
                        padding: .27rem .67rem .32rem .38rem;
                        .service-subtitle{
                            font-size: .22rem;
                            line-height: .28rem;
                            letter-spacing: .0103rem;
                        }
                        .wealth-content{
                            font-family: SourceHanSansCN-Normal;
                            font-size: .18rem;
                            line-height: .24rem;
                        }
                        .num-img{
                            width: .67rem;
                            height: .3rem;
                        }
                        .sub-line{
                            width: .35rem;
                            height: .03rem;
                            background-color: #A68576;
                            top: 1.64rem;
                            left: .4rem;
                        }
                    }
                    .list-item{
                        margin-bottom: .2rem;
                        .item-title{
                            display: flex;
                            align-items: center;
                            margin-bottom: .1rem;
                            .icon{
                                width: .18rem;
                                height: .18rem;
                                border-radius: 50%;
                                background-color: #A68576;
                                margin-right: .11rem;
                            }
                            .text{
                                font-size: .18rem;
                                line-height: .27rem;
                                color: #A68576;
                                font-weight: 500;
                            }
                        }
                        .item-desc{
                            font-size: .18rem;
                            line-height: .27rem;
                            color: #666666;
                            font-weight: 400;
                            padding-left: .29rem;
                        }
                    }
                }
                margin-bottom: .5rem;
                margin-top: .4rem;
            }
            .divider{
                width: 100%;
                margin: 0 0 .5rem;
            }
            .amc-content{
                margin-top: .5rem;
                .content-title{
                    font-size: .3rem;
                    line-height: .45rem;
                    color: #201E2E;
                }
                .advantage-desc{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                .content-desc{
                    .desc-title{
                        font-size: .25rem;
                        line-height: .38rem;
                        color: #A68576;
                        margin: .3rem 0 .2rem;
                        
                    }
                    .descFlex{
                        display: flex;
                        align-items: center;
                    }
                    .desc-text{
                        .icon{
                            width: .18rem;
                            height: .18rem;
                            margin-right: .11rem;
                        }
                        font-size: .18rem;
                        line-height: .27rem;
                        margin-bottom: .2rem;
                        color: #666666;
                        span{
                            color: #A68576;
                        }
                    }
                    .desc-item{
                        width: 5.6rem;
                        height: 5rem;
                        margin-bottom: .22rem;
                        border-radius: .08rem;
                        border: 1px solid #DADADA;
                        padding-bottom: .26rem;
                        img{
                            width: 5.6rem;
                            height: 2.9rem;
                            margin-bottom: .2rem;
                        }
                        .item-title{
                            display: flex;
                            align-items: center;
                            // .box{
                            //     width: .18rem;
                            //     height: .18rem;
                            //     border-radius: 50%;
                            //     background-color: #A68576;
                            //     margin-right: .11rem;
                            // }
                            font-size: .24rem;
                            line-height: .36rem;
                            color: #A68576;
                            margin-bottom: .15rem;
                            padding-left: .18rem;
                        }
                        .item-desc{
                            font-size: .18rem;
                            line-height: .27rem;
                            color: #666666;
                            margin-bottom: .2rem;
                            height: 1.08rem;
                            overflow-y: scroll;
                            &::-webkit-scrollbar {
                                display: none;
                            }
                            span{
                                color: #A68576;
                            }
                            padding-left: .18rem;
                        }
                        .item-li{
                            font-size: .18rem;
                            line-height: .27rem;
                            color: #666666;
                            span{
                                color: #A68576;
                            }
                            &:last-of-type{
                                margin-bottom: .2rem;
                            }
                        }
                    }
                    .last-desc{
                        margin-top: .4rem;
                    }
                }
            }
        }
    }
    .Investment_Philosophy{
        width: 11.7rem;
        .topInfo{
            background-color: rgba($color: #000000, $alpha: .6);
            width: 6rem;
            color: white;
            padding: .55rem .43rem .5rem .36rem;
            position: absolute;
            top: 2.6rem;
            left: 7.3rem;
            .top-name{
                font-size: .4rem;
                font-family: SourceHanSansCN-Medium;
                margin-bottom: .11rem;
                letter-spacing: 2.14px;
                line-height: .6rem;
            }
            .top-desc{
                font-size: .2rem;
                font-family: SourceHanSansCN-Medium;
                letter-spacing: .0067rem;
                line-height: .28rem;
            }
        }
        .detail-content{
            .title-box{
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                
                .content-title{
                    font-size: .65rem;
                    line-height: .98rem;
                    letter-spacing: .0232rem;
                }
                .content-desc{
                    color: #666666;
                    border-left: 1px solid #002E53;
                }
            }
            .content-title{
                font-size: .25rem;
                color: #A68576;
                line-height: .38rem;
                margin-bottom: .12rem;
                flex: 1;
            }
            .content-desc{
                font-size: .18rem;
                line-height: .28rem;
                color: #666666;
                margin-bottom: .25rem;
                flex: 1;
                padding-left: .24rem;
            }
            .content-li{
                margin-top: .5rem;
                margin-bottom: .5rem;
                .basis-list{
                    display: flex;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    .list-item{
                        width: 2.7rem;
                        height: 2rem;
                        position: relative;
                        cursor: pointer;
                        
                        img{
                            
                            width: 2.7rem;
                            height: 2rem;
                        }
                        .item-info{
                            position: absolute;
                            bottom: 0;
                            z-index: 2;
                            width: 2.7rem;
                            height: .5rem;
                            padding: .1rem .21rem;
                            background: #002E53;
                            transition: height .2s linear;
                            overflow: hidden;
                            box-shadow: 0px 0px 14px -5px #002E53;
                            .item-desc{
                                padding-left: 0;
                                color: #FFFFFF;
                                font-size: .16rem;
                            }
                            .item-title{
                                margin-bottom: 0;
                                .title-text{
                                    color: #fff;
                                    line-height: .34rem;

                                }
                            }
                        }
                        
                    }
                    .active-item{
                        .item-info{
                            height: 100%;
                            ::-webkit-scrollbar{
                                display: none;
                            }
                            overflow-y: scroll;
                            scrollbar-width: none;
                        }
                    }
                }
                .content-list{
                    .list-item{
                        margin-bottom: .2rem;
                        .item-title{
                            display: flex;
                            align-items: center;
                            margin-bottom: .1rem;
                            letter-spacing: 1px;
                            .title-box{
                                width: .18rem;
                                height: .18rem;
                                border-radius: 50%;
                                background-color: #A68576;
                                margin-right: .11rem;
                            }
                            .title-text{
                                font-size: .18rem;
                                line-height: .27rem;
                                color: #A68576;
                            }
                        }
                        .item-desc{
                            font-size: .18rem;
                            line-height: .27rem;
                            color: #666666;
                            padding-left: .29rem;
                            letter-spacing: 1px;
                        }
                    }
                }
            }
            .flex-box{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                .content-box{
                    width: 5.7rem;
                    min-height: 2.6rem;
                    border-radius: .04rem;
                    border: 1px solid #BAA195;
                    padding: .17rem .25rem .13rem;
                    margin-bottom: .3rem;
                    .content-title{
                        font-size: .22rem;
                        line-height: .28rem;
                        margin-bottom: 0;
                    }
                    .content-desc{
                        font-size: .18rem;
                        line-height: .28rem;
                        padding-left: 0;
                    }
                }
            }
        }
    }
}


.detailsIpad{
    .layout-content{
        width: 100%;
        padding: 0 2.625rem;
        margin-bottom: 4rem;
        .details-banner{
            width: 100%;
            height: 9.875rem;
            margin-top: 1.75rem;
        }
        .details-content{
            width: 100%;
            .content-desc{
                font-size: .35rem;
                line-height: .5rem;
            }
            .content-title{
                h5{
                    font-size: .75rem;
                    line-height: 1.15rem;
                }
            }
        }
        .Other_Investment_Services,.Asset-manager,.Alternative_Investment_Funds,.Investment_Advisory_Mandates,
        .Discretionary_Mandates,.AMC,.HK_Market,.Global_Market,.Futures_Options,.Structured_Products,.education,.Wealth_Inheritance,.Investment_Philosophy{
            width: 100%;
            .detail-title{
                font-size: .75rem;
                line-height: 1.15rem;
            }
            .detail-subtitle{
                font-size: .35rem;
                line-height: .9rem;
            }
            .detail-content{
                .text,.content-item{
                    font-size: .3rem;
                    line-height: .5rem;
                }
            }
        }
        .Asset-manager,.Discretionary_Mandates{
            width: 100%;
            .detail-subtitle{
                font-size: .35rem;
                line-height: .9rem;
            }
            .detail-content{
                height: auto;
                .content-item{
                    margin-bottom: 1.95rem;
                    justify-content: space-between;
                    .item-left{
                        width: 9rem;
                        img{
                            width: .875rem;
                            height: .4rem;
                        }
                        .item-title{
                            font-size: .45rem;
                            line-height: .675rem;
                            margin-bottom: .2rem;
                        }
                        .item-content{
                            font-size: .3rem;
                            line-height: .5rem;
                        }
                    }
                    .item-right{
                        width: 9rem;
                        height: 3.6rem;
                        img{
                            height: 100%;
                        }
                    }
                }
            }
            .detail-footer{
                margin-top: 1.775rem;
                p{
                    font-size: .35rem;
                    line-height: .75rem;
                }
            }
        }
        .Other_Investment_Services{
            width: 100%;
            .detail-content{
                .text{
                    font-size: .35rem;
                    line-height: .9rem;
                }
                .img{
                    width: 16rem;
                    height: 7.2rem;
                    margin-top: 1rem;
                    margin-bottom: 1.5rem;
                }
                .round{
                    margin-top: .36rem;
                }
            }
        }
        .Alternative_Investment_Funds{
            .detail-content{
                .item{
                    .img{
                        width: .62rem;
                        height: .28rem;
                        margin-top: .06rem;
                        margin-right: .2rem;
                    }
                }
            }
        }
        .Investment_Advisory_Mandates{
            .detail-content{
                .content-image{
                    width: 16rem;
                    height: 7.2rem;
                }
            }
        }
        .Discretionary_Mandates{
            .subtitle-desc{
                span{
                    font-size: .3rem;
                    line-height: .5rem;
                }
            }
            .detail-desc{
                .desc-item{
                    font-size: .3rem;
                    line-height: .5rem;
                    &:nth-child(4){
                        font-size: .35rem;
                        line-height: .9rem;
                    }
                }
            }
            .detail-content{
                .content-title{
                    font-size: .45rem;
                    line-height: .675rem;
                    margin-bottom: 1rem;
                }
            }
        }
        .AMC{
            .detail-subtitle{
                .subtitle-text{
                    font-size: .35rem;
                    line-height: .9rem;
                }
            }
            .detail-content{
                .content-img{
                    .item-img{
                        width: 6.5rem;
                        height: 6.5rem;
                        margin-right: 1rem;
                        
                    }
                    .item-text{
                        width: calc(100% - 7.5rem);
                        margin-top: 1rem;
                        .text{
                            font-size: .3rem;
                            line-height: .5rem;
                        }
                    }
                }
                .content-item{
                    .content-title{
                        .title,.subtitle{
                            font-size: .3rem;
                            line-height: .5rem;
                        }
                    }
                    .content{
                        .content-list{
                            li{
                                font-size: .3rem;
                                line-height: .5rem;
                                .round{
                                    width: .24rem;
                                    height: .24rem;
                                    margin-right: .1rem;
                                }
                            }
                        }
                    }
                }
            }
        }
        .HK_Market{
            .detail-subtitle{
                .subtitle-item{
                    span{
                        font-size: .3rem;
                        line-height: .5rem;
                    }
                }
            }
            .detail-content{
                .content-item{
                    .item-text{
                        font-size: .3rem;
                        line-height: .5rem;
                    }
                    .item-img{
                        width: 16rem;
                        height: 7.2rem;
                    }
                }
            }
        }
        .Global_Market{
            .detail-content{
                .content-item{
                    .item-text{
                        font-size: .3rem;
                        line-height: .5rem;
                    }
                    img{
                        width: 16rem;
                        height: 7.2rem;
                        margin-top: .5rem;
                        margin-bottom: .8rem;
                    }
                }
            }
        }
        .Futures_Options{
            .detail-content{
                .content-title{
                    font-size: .35rem;
                    line-height: .9rem;
                }
                .content{
                    .text{
                        font-size: .3rem;
                        line-height: .5rem;
                    }
                    .img{
                        width: 16rem;
                        height: 7.2rem;
                    }
                    .content-list{
                        li{
                            font-size: .3rem;
                            line-height: .5rem;
                        }
                    }
                }
            }
        }
        .Structured_Products{
            .detail-subtitle{
                p{
                    font-size: .35rem;
                    line-height: .9rem;
                }
            }
            .detail-content{
                .text{
                    font-size: .3rem;
                    line-height: .5rem;
                }
                .content-list{
                    li{
                        font-size: .3rem;
                        line-height: .5rem;
                    }
                }
            }
        }
        .education{
            .detail-content{
                .content-item{
                    .item-title{
                        font-size: .4rem;
                        line-height: .9rem;
                    }
                    .item-content{
                        .content-li{
                            font-size: .3rem;
                            line-height: .5rem;
                        }
                        .content-title{
                            font-size: .35rem;
                            line-height: .52rem;
                        }
                        .content-item{
                            .item-title{
                                font-size: .32rem;
                                line-height: .5rem;
                                font-family: SourceHanSansCN-Regular;
                                color: #333333;
                            }
                            .item-desc{
                                font-size: .3rem;
                                line-height: .5rem;
                            }
                        }
                        >div{
                            .content-item{
                                .content-item-title{
                                    height: 1rem;
                                    line-height: 1rem;
                                }
                                .content-item-desc{
                                    font-size: .3rem;
                                    line-height: .5rem;
                                    height: 3rem;
                                }
                            }
                        }
                        .bcwhite{
                            font-size: .3rem;
                            line-height: .5rem;
                            height: auto;
                            padding: .1rem;
                        }
                    }
                    .item-table{
                        >div{
                            .content-item{
                                .content-item-desc{
                                    height: auto;
                                }
                            }
                        }
                    }
                    .scene-content{
                        .content-list{
                            .content-li-title{
                                line-height: 3rem;
                            }
                            .content-li-desc{
                                font-size: .3rem;
                                line-height: .5rem;
                            }
                        }
                    }
                    .type{
                        .type-item{
                            .type-title{
                                font-size: .35rem;
                                line-height: 1rem;
                                height: 1rem;
                            }
                            .type-desc{
                                .desc{
                                    font-size: .3rem;
                                    line-height: .5rem;
                                    height: 2rem;

                                }
                            }
                        }
                    }
                }
                
            }
        }
        .Wealth_Inheritance{
            .topInfo{
                width: 10.5rem;
                left: 2.62rem;
                top: 7rem;
                height: 4rem;
                .top-name{
                    font-size: .8rem;
                }
                .top-desc{
                    font-size: .45rem;
                    line-height: .6rem;
                }
            }
            .detail-content{
                .detail-desc{
                    .desc-title{
                        img{
                            width: .875rem;
                        }
                        .title-text{
                            font-size: .45rem;
                            line-height: .675rem;
                            margin-top: .125rem;
                            margin-bottom: .225rem;
                        }
                    }
                    .desc-info{
                        .info-text{
                            font-size: .325rem;
                            line-height: .5rem;
                        }
                    }
                }
                .family-Structure{
                    width: 18.025rem;
                    height: 10.675rem;
                    margin: 1.425rem auto;
                }
                .Family-Advantage{
                    width: 13.95rem;
                    height: 12rem;
                }
                .advantage-content{
                    .desc-title{
                        img{
                            width: .875rem;
                        }
                        .title-text{
                            font-size: .45rem;
                            line-height: .675rem;
                            margin-top: .125rem;
                            margin-bottom: .225rem;
                        }
                    }
                    .desc-content{
                        .list-box{
                            .AssetManagementItem{
                                width: 9.6rem;
                                height: 4.22rem;
                                .num-img{
                                    width: 1.67rem;
                                    height: 0.76rem;
                                }
                                .service-subtitle{
                                    font-size: .45rem;
                                    line-height: .6rem;
                                    margin-bottom: .2rem;
                                }
                                .wealth-content{
                                    font-size: .35rem;
                                    line-height: .45rem;
                                }
                                .sub-line{
                                    width: .9rem;
                                    height: .05rem;
                                    top: 3.72rem;
                                    left: 0.48rem;
                                }
                            }
                        }
                        .list-item{
                            margin-bottom: .375rem;
                            .item-title{
                                margin-bottom: .175rem;
                                .icon{
                                    width: .325rem;
                                    height: .325rem;
                                    margin-right: .2rem;
                                }
                                .text{
                                    font-size: .325rem;
                                    line-height: .475rem;
                                }
                            }
                            .item-desc{
                                font-size: .325rem;
                                line-height: .475rem;
                                padding-left: .525rem;
                            }
                        }
                    }
                }
                .divider{
                    margin: .75rem 0;
                }
                .amc-content{
                    .content-title{
                        font-size: .5rem;
                        line-height: .75rem;
                    }
                    
                    .content-desc{
                        .desc-title{
                            font-size: .45rem;
                            line-height: .675rem;
                            margin-top: .125rem;
                            margin-bottom: .225rem;
                        }
                        .desc-text{
                            font-size: .325rem;
                            line-height: .475rem;
                            margin: .15rem 0 .3rem;
                            .text{
                                font-size: .325rem;
                                line-height: .475rem;
                            }
                            .icon{
                                width: .35rem;
                                height: .35rem;
                                margin-right: .2rem;
                            }
                        }
                        .desc-item{
                            .item-title{
                                font-size: .35rem;
                                line-height: .5rem;
                                .box{
                                    width: .35rem;
                                    height: .35rem;
                                    margin-right: .2rem;
                                }
                            }
                            .item-desc{
                                font-size: .325rem;
                                line-height: .475rem;
                                margin: 0;
                                margin: .15rem 0 .3rem;
                                padding-left: .5rem;
                            }
                            .item-li{
                                font-size: .325rem;
                                line-height: .475rem;
                                padding-left: .5rem;

                                &:last-of-type{
                                    margin-bottom: .3rem;
                                }
                            }
                        }
                    }
                    .advantage-desc{
                        .desc-title{
                            width: 100%;
                        }
                        .desc-item{
                            width: 9.6rem;
                            height: 8.57rem;
                            margin-bottom: .4rem;
                            border-radius: .2rem;
                            img{
                                width: 100%;
                                height: 4.9rem;
                            }
                            .item-title{
                                padding: 0 .3rem;
                                font-size: .4rem;
                                line-height: .55rem;
                                .box{
                                    display: none;
                                }
                            }
                            .item-desc{
                                padding: 0 .3rem;
                                font-size: .35rem;
                                line-height: .45rem;
                                height: 2.25rem;
                                .item-li{
                                    padding-left: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .Investment_Philosophy{
            .topInfo{
                width: 10.5rem;
                left: 2.62rem;
                top: 7rem;
                height: 4rem;
                .top-name{
                    font-size: .8rem;
                }
                .top-desc{
                    font-size: .45rem;
                    line-height: .6rem;
                }
            }
            .detail-content{
                .title-box{
                    .content-title{
                        font-size: 1rem;
                    }
                }
                .content-title{
                    font-size: .45rem;
                    line-height: .675rem;
                    margin-top: .125rem;
                    margin-bottom: .225rem;
                }
                .content-desc{
                    font-size: .325rem;
                    line-height: .5rem;
                }
                .content-li{
                    margin: .75rem 0;
                    .basis-list{
                        .list-item{
                            width: 4.7rem;
                            height: 3.5rem;
                            img{
                                width: 100%;
                                height: 100%;
                            }
                            .item-info{
                                width: 100%;
                                height: 0.82rem;
                                padding: .24rem .4rem 0;
                                .item-title{
                                    font-size: .45rem;
                                    line-height: .5rem;
                                    margin-bottom: .3rem;
                                }
                                .item-desc{
                                    padding-left: 0;
                                    font-size: .3rem;
                                    line-height: .35rem;
                                }
                            }
                            &:hover{
                                .item-info{
                                    height: 100%;
                                }
                            }
                        }
                    }
                    .content-list{
                        .list-item{
                            margin-bottom: .5rem;
                            .item-title{
                                margin-bottom: .25rem;
                                .title-box{
                                    width: .35rem;
                                    height: .35rem;
                                }
                                .title-text{
                                    font-size: .35rem;
                                    line-height: .35rem;
                                }
                            }
                            .item-desc{
                                font-size: .325rem;
                                line-height: .5rem;
                                padding-left: .45rem;
                            }
                        }
                    }
                }
                .flex-box{
                    .content-box{
                        width: 9.7rem;
                        min-height: 3.8rem;
                        border-radius: .125rem;
                        .content-title{
                            font-size: .4rem;
                            line-height: .6rem;
                            margin-bottom: .2rem;
                        }
                        .content-desc{
                            font-size: .3rem;
                            line-height: .45rem;
                        }
                    }
                }
            }
        }
    }
}

.detailsMobile{
    margin-top: 0;
    .details-banner{
        margin-top: 4.0625rem;
        width: 20.625rem;
        height: 10.375rem;
        position: relative;
        margin-bottom: 2rem;
        .banner-logo{
            width: 10.9375rem;
            height: 3.6875rem;
            position: absolute;
            left: 4.875rem;
            top: 3.375rem;
        }
    }
    .details-content{
        width: 20.625rem;
        .content-title{
            h5{
                @include contentSubtitle();
            }
            margin-bottom: 2rem;
        }
        .content-desc{
            p{
                @include contentFont();
                line-height: 1.25rem;
                color: #1F1E2E;
            }
        }
    }
    .Asset-manager,.Discretionary_Mandates{
        width: 20.9375rem;
        .detail-title{
            font-size: 1.125rem;
            line-height: 1.5625rem;
            letter-spacing: .04rem;
            margin: 3.125rem 0 2.5rem;
        }
        .detail-subtitle{
            font-size: .75rem;
            .subtitle-item{
                margin-bottom: 1.1875rem;
                span{
                    line-height: 1.0625rem;
                }
                img{
                    width: 1rem;
                    height: 1rem;
                    margin-right: .5rem;
                }
            }
        }
        .detail-content{
            .content-item{
                flex-wrap: wrap;
                margin-bottom: 3.125rem;
                .m130{
                    margin: 0;
                }
                &:nth-child(2n+1){
                    flex-direction: column-reverse;
                    .item-left{
                        margin-top: 3.125rem;
                    }
                }
                .item-right{
                    width: 20.9375rem;
                    height: auto;
                    img{
                        width: 20.9375rem;
                        height: 8.3125rem;
                    }
                }
                .item-left{
                    width: 20.9375rem;
                    &:nth-of-type(2){
                        margin-top: 3.125rem;
                    }
                    .item-content{
                        width: auto;
                        font-size: .75rem;
                        line-height: 1.125rem;
                    }
                    img{
                        width: 2rem;
                        height: 1rem;
                    }
                    .item-title{
                        font-size: 1rem;
                        line-height: 1.5rem;
                        margin-bottom: .5rem;
                    }
                }
            }
        }
        .detail-footer{
            margin-bottom: 0;
            p{
                font-size: .75rem;
                line-height: 1.125rem;
                margin-bottom: .625rem;
            }
        }
    }
    .Discretionary_Mandates,.Investment_Advisory_Mandates,.HK_Market,.Alternative_Investment_Funds,
    .AMC,.Global_Market,.Futures_Options,.Structured_Products,.Other_Investment_Services,.education,.Wealth_Inheritance,.Investment_Philosophy{
        width: 20.9375rem;
        .detail-title{
            font-size: 1.125rem;
            line-height: 1.5625rem;
            letter-spacing: .04rem;
            margin: 3.125rem 0 2.5rem;
        }
        .detail-content{
            .content-item{
                font-size: .75rem;
                line-height: 1.125rem;
            }
            .content-image{
                width: 16rem;
                height: 7.2rem;
                margin-top: 2.3rem;
            }
        }
    }
    .Discretionary_Mandates{
        .detail-subtitle{
            .subtitle-desc{
                margin-bottom: 1rem;
                span{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
            }
        }
        .detail-desc{
            margin-bottom: 5rem;
            .desc-item{
                font-size: 0.75rem;
                line-height: 1.2rem;
                margin-bottom: .8rem;
                &:nth-child(4){
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
            }
        }
        .detail-content{
            .content-title{
                font-size: 1rem;
                line-height: 1.5rem;
                font-weight: 500;
            }
            .content-item{
                .m130{
                    margin: 2rem 0;
                }
            }
        }
    }
    .AMC{
        .detail-subtitle{
            margin-bottom: 1rem;
                .subtitle-text{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
        }
        .detail-content{
            .content-img{
                display: block;
                .item-img{
                    width: 15rem;
                    height: 15rem;
                    margin: 2rem auto;
                }
                .item-text{
                    width: 100%;
                    .text{
                        font-size: 0.75rem;
                        line-height: 1.2rem;
                    }
                }
            }
            .content-item{
                .content-title{
                    .title{
                        font-size: 0.75rem;
                        line-height: 1.2rem;
                        margin-bottom: .5rem;
                    }
                    .subtitle{
                        font-size: 0.75rem;
                        line-height: 1.2rem;
                    }
                }
                .content{
                    .content-list{
                        margin-top: 1rem;
                        li{
                            font-size: 0.75rem;
                            line-height: 1.2rem;
                            margin-bottom: .4rem;
                            &:nth-child(4){
                                align-items: normal;
                                .round{
                                    margin-top: .38rem;
                                }
                            }
                            .round{
                                width: .4rem;
                                height: .4rem;
                                margin-right: .6rem;
                            }
                            span{
                                width: calc(100% - .4rem);
                            }
                        }
                    }
                }
            }
        }
    }
    .HK_Market{
        .detail-subtitle{
            .subtitle-item{
                span{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
            }
        }
        .detail-content{
            .content-item{
                .item-img{
                    width: 16rem;
                    height: 7.2rem;
                    margin: 1.3rem auto 1.6rem;
                }
                .item-text{
                    .text-content{
                        font-size: 0.75rem;
                        line-height: 1.2rem;
                    }
                }
            }
            .content-footer{
                .item-text{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
            }
        }
    }
    .Other_Investment_Services{
        .detail-content{
            .text{
                font-size: 0.75rem;
                line-height: 1.2rem;
                width: calc(100% - .625rem);
                margin-bottom: .8rem;
            }
            .round{
                width: .625rem;
                height: .625rem;
                margin-top: .3rem;
                margin-right: .4rem;
            }
            .img{
                width: 16rem;
                height: 7.2rem;
                margin: 1rem auto 2rem;
            }
        }
    }
    .Alternative_Investment_Funds{
        .detail-content{
            .item{
                margin-bottom: .8rem;
                .text{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
                .img{
                    width: 1.5rem;
                    height: .66rem;
                    margin-right: .8rem;
                }
            }
        }
    }
    .Global_Market{
        .detail-content{
            .content-item{
                .item-text{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
                img{
                    width: 16rem;
                    height: 7.2rem;
                    margin: 1rem auto 2rem;
                }
            }
        }
    }
    .Futures_Options{
        .detail-content{
            .content-title{
                font-size: 1rem;
                line-height: 1.5rem;
                margin: 1.2rem 0;
            }
            .content{
                .text{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                }
                .img{
                    width: 16rem;
                    height: 7.2rem;
                    margin: 1rem auto 2rem;
                }
                .content-list{
                    margin: 1rem 0 .6rem;
                    li{
                        align-items: normal;
                        font-size: 0.75rem;
                        line-height: 1.2rem;
                        margin-bottom: .5rem;
                        .round{
                            width: .625rem;
                            height: .625rem;
                            margin-right: .2rem;
                            margin-top: .25rem;
                        }
                    }
                }
            }
        }
    }
    .Structured_Products{
        .detail-subtitle{
            p{
                font-size: .8rem;
                line-height: 1.3rem;
            }
        }
        .detail-content{
            .text{
                font-size: 0.75rem;
                line-height: 1.2rem;
                margin-bottom: .5rem;
            }
            .content-list{
                li{
                    font-size: 0.75rem;
                    line-height: 1.2rem;
                    .round{
                        width: .6rem;
                        height: .6rem;
                        margin-right: .4rem;
                    }
                }
            }
        }
    }
    .education{
        .detail-content{
            >.content-item{
                margin-top: 2rem;
                .item-title{
                    font-size: 1rem;
                    line-height: 1.5rem;
                    letter-spacing: .04rem;
                }
                .item-content{
                    font-size: .75rem;
                    line-height: 1.125rem;
                    letter-spacing: .0313rem;
                    margin-top: .875rem;
                    .content-item{
                        margin-bottom: .625rem;
                        .content-title{
                            margin-bottom: .625rem;
                            margin-top: 1.875rem;
                            font-size: 1rem;
                            line-height: 1.5rem;
                        }
                        .item-title{
                            margin-bottom: .3125rem;
                            font-size: .875rem;
                        }
                        .item-desc{
                            font-size: .75rem;
                        }
                        .fixedType{
                            .content-list{
                                .content-li-title{
                                    height: 1.875rem;
                                    line-height: 1.875rem;
                                }
                            }
                        }
                        .fixedRisk{
                            .content-list{
                                .content-li-title{
                                    height: 1.875rem;
                                    line-height: 1.875rem;
                                }
                            }
                        }
                    }
                    .content-li{
                        margin-bottom: .625rem;
                    }
                    .content-img{
                        height: 13rem;
                    }
                    .scene-content{
                        .content-list{
                            height: auto;
                            margin-top: .625rem;
                            .content-li-title{
                                >p{
                                    line-height: .8rem;
                                }
                            }
                            .content-li-desc{
                                padding: .625rem;
                                >p{
                                    margin-bottom: .625rem;
                                    font-size: .75rem;
                                }
                            }
                        }
                    }
                    .bcwhite{
                        font-size: .75rem;
                        height: auto;
                        padding: .8rem;
                    }
                }
                .item-table{
                    >div{
                        display: block;
                        .content-item{
                            width: 100%;
                            .content-item-title{
                                height: 1.875rem;
                                line-height: 1.875rem;
                                padding-left: .625rem;
                            }
                            .content-item-desc{
                                padding: .3125rem .625rem 0;
                                height: 8.75rem;
                                font-size: .75rem;
                            }
                        }
                    }
                    .content-item{
                        margin-top: 1.25rem;
                    }
                }
                .item-consideration{
                    >div{
                        .content-item{
                            height: 8.75rem;
                            .content-item-desc{
                                height: auto;
                            }
                        }
                    }
                }
                .type{
                    .type-item{
                        .type-title{
                            height: 2.5rem;
                            line-height: 2.5rem;
                            padding-left: .3125rem;
                        }
                        .type-desc{
                            .desc{
                                height: 7.5rem;
                                padding: .3125rem;
                                >p{
                                    margin-bottom: .3125rem;
                                }
                            }
                        }
                    }
                }
                .fixedScene{
                    .content-item{
                        .item-desc{
                            margin: .625rem 0;
                        }
                        .item-img{
                            margin-top: .9375rem;
                        }
                    }
                }
            }
        }
    }
    .Wealth_Inheritance{
        .topInfo{
            width: 16.25rem;
            height: auto;
            top: 7.5rem;
            left: 2.46rem;
            padding: 1rem .625rem;
            .top-name{
                font-size: 1.0625rem;
                line-height: 1.625rem;
            }
            .top-desc{
                font-size: .75rem;
                line-height: .75rem;
            }
        }
        .detail-content{
            .detail-desc{
                .desc-title{
                    img{
                        width: 2.0625rem;
                    }
                    .title-text{
                        font-size: 1rem;
                        line-height: 1.5rem;
                        margin-top: .3125rem;
                        margin-bottom: .5rem;
                    }
                }
                .desc-info{
                    .info-text{
                        font-size: .75rem;
                        line-height: 1.1875rem;
                        margin-bottom: .625rem;

                    }
                }
            }
            .family-Structure{
                margin: 3.125rem auto 2.5rem;
                width: 100%;
                height: auto;
            }
            .Family-Advantage{
                width: 100%;
                height: auto;
            }
            .advantage-content{
                .desc-title{
                    img{
                        width: 2.0625rem;
                    }
                    .title-text{
                        font-size: 1rem;
                        line-height: 1.5rem;
                        margin-top: .3125rem;
                        margin-bottom: .5rem;
                    }
                }
                .desc-content{
                    .list-box{
                        display: block;
                        .AssetManagementItem{
                            width: 100%;
                            height: 9.375rem;
                            border-radius: .75rem;
                            margin-bottom: 1.0625rem;
                            padding: 1rem 1.4375rem;
                            .num-img{
                                width: 2.5rem;
                                height: 1.125rem;
                            }
                            .service-subtitle{
                                font-size: 1.0625rem;
                                line-height: 1.625rem;

                            }
                            .wealth-content{
                                font-size: .875rem;
                                line-height: 1.125rem;
                            }
                            .sub-line{
                                width: 1.25rem;
                                height: .125rem;
                                top: 8.2rem;
                                left: 1.5rem;
                            }
                        }
                    }
                    .list-item{
                        margin-bottom: .75rem;
                        .item-title{
                            margin-bottom: .1875rem;
                            align-items: baseline;
                            .icon{
                                flex-shrink: 0;
                                width: .75rem;
                                height: .75rem;
                                margin-right: .25rem;
                            }
                            .text{
                                font-size: .75rem;
                                line-height: 1.125rem;
                            }
                        }
                        .item-desc{
                            font-size: .75rem;
                            line-height: 1rem;
                            padding-left: 1rem;
                        }
                    }
                }
            }
            .divider{
                margin: 1.25rem 0;
            }
            .amc-content{
                .content-title{
                    font-size: 1.125rem;
                    line-height: 1.5rem;
                }
                .content-desc{
                    .desc-text{
                        font-size: .75rem;
                        line-height: 1rem;
                        margin-bottom: .625rem;
                        align-items: baseline;
                        .icon{
                            width: .75rem;
                            height: .75rem;
                            margin-right: .375rem;
                        }
                    }
                    .desc-title{
                        font-size: 1rem;
                        line-height: 1.5rem;
                        margin-top: .3125rem;
                        margin-bottom: .5rem;
                    }
                    .desc-item{
                        .item-title{
                            font-size: .875rem;
                            line-height: 1.125rem;
                            .box{
                                width: .75rem;
                                height: .75rem;
                                margin-right: .375rem;
                            }
                        }
                        .item-desc{
                            font-size: .75rem;
                            line-height: 1rem;
                            padding-left: 1.125rem;
                            margin: .3125rem 0 .5rem;
                        }
                        .item-li{
                            font-size: .75rem;
                            line-height: 1rem;
                            padding-left: 1.125rem;
                            &:last-of-type{
                                margin-bottom: 1.25rem;
                            }
                        }
                    }
                }
                .advantage-desc{
                    display: block;
                    .desc-item{
                        width: 100%;
                        height: 18.5625rem;
                        margin-bottom: .875rem;
                        border-radius: .3125rem;
                        img{
                            height: 10.75rem;
                            width: 100%;
                            margin-bottom: 1.4375rem;
                        }
                        .item-title{
                            font-size: .875rem;
                            line-height: 1.375rem;
                            padding-left: .6875rem;
                            .box{
                                display: none;
                            }
                        }
                        .item-desc{
                            font-size: .75rem;
                            line-height: 1rem;
                            height: 3rem;
                            padding-left: .6875rem;
                            .item-li{
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .Investment_Philosophy{
        .topInfo{
            width: 16.25rem;
            height: auto;
            left: 2.44rem;
            top: 7.5rem;
            padding: .5rem .625rem;
            .top-name{
                font-size: 1.0625rem;
                margin-bottom: 0;
                line-height: 1.625rem;
            }
            .top-desc{
                font-size: .75rem;
                line-height: 1.125rem;
            }
        }
        .detail-content{
            .title-box{
                display: block;
                .content-title{
                    font-size: 2rem;
                    line-height: 3.0625rem;
                    letter-spacing: .0725rem;
                    margin-bottom: 1.5rem;
                }
                .content-desc{
                    padding-left: .75rem;
                    font-size: .75rem;
                    line-height: 1.125rem;
                }
            }
            .content-title{
                font-size: 1rem;
                line-height: 1.5rem;
                margin-top: .3125rem;
                margin-bottom: .5rem;
            }
            .content-desc{
                font-size: .75rem;
                line-height: 1.1875rem;
            }
            .content-li{
                margin: 2.5rem 0;
                .basis-list{
                    display: block;
                    .list-item{
                        width: 100%;
                        height: 15.375rem;
                        margin-bottom: 1.5rem;
                        img{
                            width: 100%;
                            height: 100%;
                        }
                        .item-info{
                            width: 100%;
                            height: 3.875rem;
                            padding: .9375rem .625rem;
                            .item-title{
                                margin-bottom: 1.0625rem;
                                .title-text{
                                    font-size: 1.375rem;
                                    line-height: 1.9375rem;
                                }
                                
                            }
                            .item-desc{
                                font-size: 1rem;
                                line-height: 1.3125rem;
                            }
                        }
                    }
                    .active-item{
                        .item-info{
                            height: 100%;
                        }
                    }
                }
                .content-list{
                    .list-item{
                        .item-title{
                            margin-bottom: .625rem;
                            .title-box{
                                width: 1rem;
                                height: 1rem;
                                margin-right: .375rem;
                            }
                            .title-text{
                                font-size: .875rem;
                                line-height: 1.125rem;
                            }
                        }
                        .item-desc{
                            font-size: .75rem;
                            line-height: 1.1875rem;
                            margin-bottom: 1.25rem;
                            padding-left: 1.125rem;
                        }
                    }
                }
            }
            .flex-box{
                
                .content-box{
                    width: 100%;
                    min-height: 6.5625rem;
                    border-radius: .125rem;
                    padding: .5625rem .9375rem;
                    margin-bottom: .6875rem;
                    .content-title{
                        font-size: .75rem;
                        line-height: 1rem;
                    }
                    .content-desc{
                        font-size: .625rem;
                        line-height: 1rem;
                    }
                }
            }
        }
    }
    .Bond{
        .detail-content{
            .content-item{
                .scene-content{
                    .content-list{
                        height: auto;
                        .content-li-desc{
                            height: auto;
                            padding: .3125rem .6875rem .3125rem;
                        }
                    }
                }
            }
        }
    }
    .OPTION{
        .detail-content{
            .item-content{
                .bcwhite{
                    height: 6.25rem;
                    border-width: .5rem;
                }
                .option-img{
                    height: 11rem;
                    margin: 2.5rem 0;
                }
                .option-imglist{
                    .option-imgli{
                        height: 5rem;
                    }
                }
            }
            .content-item{
                
                
            }
        }
    }
}

