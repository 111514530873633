.ProductItem{
    width: 3.75rem;
    height: 6.72rem;
    padding: .53rem .17rem 0;

    text-align: center;
    background-color: #fff;    
    // box-shadow: 0rem 0rem .24rem -0.08rem #E3E3E3;
    // border-radius: .15rem;
    opacity: 0.94;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: .3rem;
    &:hover{
        background-color: rgba($color: #002E53, $alpha: .95);
        color: white;
        .item-content{
            color: white;
        }
        .item-header > .img{
            border-color: #fff;
        }
    }

    .item-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        .img{
            width: .98rem;
            height: .98rem;
            border: .02rem solid #1F1E2E;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            transition: border 0.3s;
            img{
                width: .52rem;
                height: .52rem;
                border-radius: 50%;
            }
        }
        >p{
            margin-block: .12rem;
        }
        .index-subtitle{
            width: 3.41rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            
        }
        .line{
            width: 1.28rem;
            height: .03rem;
            border-radius: .06rem;
            background-color: #A68576;
        }
    }
    .item-content{
        margin-top: .35rem;
        line-height: .25rem;
        padding: 0 .16rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        text-align: left;
        color: #666;
    }
    // .index-content{
    //     font-size: .2rem;
    // }
}

.ServiceItem{
    width: 4.86rem;
    height: 3.5rem;
    padding: .42rem .43rem 0;
    background: #FFFFFF;
    box-shadow: 0rem 0rem .24rem -0.08rem #E3E3E3;
    border-radius: .15rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    transition: box-shadow 0.3s, background-color 0.3s, color 0.3s;
    &:hover{
        box-shadow: 0rem 0rem .24rem -0.08rem #002E53;
        background-color: #002E53;
        color: white;
        .item-content{
            color: white;
        }
    }
    // margin: 0 .8rem;
    // &:nth-child(2n){
    //     margin-right: 0;
    // }
    // &:nth-child(2n+1){
    //     margin-left: 0;
    // }
    img{
        width: .8rem;
        height: .8rem;
    }
    .item-title{
        margin: .18rem auto .31rem;
        line-height: .33rem;
    }
    .item-content{
        line-height: .32rem;
        font-size: .2rem;
        color: #666;
    }
}

.InsightItem{
    width: 7.5rem;
    height: 5rem;
    box-shadow: 0rem 0rem .24rem -0.08rem #E3E3E3;
    // border-radius: .15rem;
    
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;
    position: relative;
    .bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }
    .insight-content{
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
        padding: .7rem .48rem 0;
        background-color: rgba(0, 46, 83, 0.9);
        color: white;
        // &:hover{
        //     background-color: rgba(166, 133, 118, 0.9);
        // }
    }
    .download{
        position: absolute;
        // left: calc(50% - .63rem);
        // top: calc(50% - .83rem);
        display: none;
        text-align: center;
        z-index: 3;
        width: 100%;
        height: 100%;
        background-color: rgba(166, 133, 118, 0.9);
        padding-top: 1.5rem;
        cursor: pointer;
        img{
            width: 1.25rem;
            height: 1.25rem;
        }
        >p{
            font-size: .18rem;
            letter-spacing: .0064rem;
            line-height: .25rem;
        }
    }
    &:hover{
        color: #002E53;
        background-color: rgba($color: #002E53, $alpha: .9);
        opacity: 0.9;
        .download{
            display: block;
            >p{
                color: white;
            }
        }
        img{
            // transform: translateX(-100%);
            // filter: drop-shadow(.8rem 0 rgb(255, 255, 255));
        }
    }
    
    .img{
        overflow: hidden;
        img{
            width: .8rem;
            height: 1.22rem;
        }
    }
    .line{
        width: .46rem;
        height: .04rem;
        margin-left: 0;
        background-color: #A68576;
    }
    .item-title{
        // line-height: .33rem;
        line-height: .56rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: .4rem;
    }
    .index-content{
        font-size: .2rem;
        margin-top: .58rem;
        // line-height: .25rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
}

.EducationItem{
    width: 7.5rem;
    height: 4rem;
    padding-top: .75rem;
    padding-left: .42rem;
    padding: 1rem .6rem .9rem;
    background: #FFFFFF;
    // box-shadow: 0rem 0rem .24rem -0.08rem #E3E3E3;
    // border-radius: .11rem;
    border: 1px solid #C0CBD4;
    border-right: none;

    text-align: left;
    position: relative;
    // margin-bottom: .3rem;
    cursor: pointer;
    transition: box-shadow 0.3s;

    &:hover{
        // background: url("../../images/bg/bg-education.png") no-repeat center;
        // background-size: cover;
        box-shadow: 0rem 0rem .24rem -0.08rem #A68576;
        background-color: #012E54;
        color: white;
        .index-content{
            color: white;
        }
    }
    &:nth-child(2n){
        border-right: 1px solid #C0CBD4;
    }
    &:nth-child(3){
        border-top: none;
    }
    &:nth-child(4){
        border-top: none;
    }
    .item-title{
        line-height: .33rem;
        margin-bottom: .3rem;
        font-size: .4rem;
        line-height: .56rem;
    }
    .item-content{
        // font-size: .18rem;
        // line-height: .25rem;
        letter-spacing: 0.0075rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        font-size: .2rem;
        line-height: .28rem;
        color: #666;
    }
}