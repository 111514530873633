body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select:text;
  color: #1F1E2E;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, ul, li, ol, dl, dd, dt, p, h1, h2, h3, h4, h5, h6, form, fieldset, legend, img {
  margin: 0;
  padding: 0;
}

ul, ol {
  list-style: none;                /*清除列表风格*/
}

/* 全局配置 */

.wordselect{
  user-select: text;
}
.m-auto{
  margin: 0 auto;
}
.fillImg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.index-title{
  font-size: 32px;
  font-weight: 500;
  line-height: 45px;
  letter-spacing: 1.14px;
  text-align: center;
}

.index-content{
  font-size: 18px;
  letter-spacing: 0.64px;
}

.IndexMobile .index-subtitle{
    font-size: 18px;
    letter-spacing: 0.64px;
    line-height: 25px;
}

.IndexMobile .index-content{
    font-size: 12px;                       
}

.IndexMobile .index-title{
  font-size: 18px;
  letter-spacing: .64px;
  line-height: 25px;
  font-family: PingFangSC-Medium, PingFang SC;
}
