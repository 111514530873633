@mixin contentFont {
    font-size: .75rem;
    line-height: 1.0625rem;
    letter-spacing: .0269rem;
}
@mixin contentSubtitle{
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: .04rem;
}

.list-page{
    padding-bottom: 200px;
    .list-banner{
        height: 4.88rem;
        background-color: #0B395D;
        color: white;
        padding-top: 2.16rem;
        text-align: center;
        margin-bottom: 2.25rem;
        >h4{
            font-size: .6rem;
            letter-spacing: .02rem;
            margin-bottom: .21rem;
        }
        >p{
            font-size: .18rem;
            letter-spacing: .006rem;
            line-height: .25rem;
            width: 9.4rem;
            margin: 0 auto;
        }
    }
    .list-content{
        min-height: 1rem;
        .content-title{
            position: relative;
            width: 15rem;
            margin: 0 auto;
            .ant-divider{   
                border-color: #A68576;       
                .ant-divider-inner-text{
                }
            }
            .list-title{
                font-size: .6rem;
                letter-spacing: .0214rem;
                // position: absolute;
                // left: -0.25rem;
                // top: -0.6rem;
                display: inline-block;
                // background-color: #f5f5f5;
                padding: 0 .25rem;
            }
            .learn-more{
                display: inline-block;
                position: absolute;
                top: -0.36rem;
                right: 0;
                
                font-size: .18rem;
                color: #A68576;
                background-color: #f5f5f5;
                padding: 0 .2rem;
            }
        }
        .content-list{
            width: 15rem;
            margin: .97rem auto 1rem;
            .list-item{
                display: flex;
                margin-bottom: .7rem;
                .img{
                    background-color: #0B395D;
                    width: 4rem;
                    height: 2.36rem;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .list-text{
                    margin-left: .77rem;
                    position: relative;
                    width: calc(100% - 4.77rem);
                    .text-title{
                        font-size: .26rem;
                        letter-spacing: .009rem;
                        line-height: .37rem;
                        margin-bottom: .22rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    .news-title{
                        cursor: pointer;
                    }
                    .text-desc{
                        font-size: .2rem;
                        letter-spacing: .0071rem;
                        line-height: .3rem;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                    }
                    .text-down{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        font-size: .18rem;
                        color: #0172C8;
                        letter-spacing: .0064rem;
                        text-decoration: underline;
                    }
                    .learn-more{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        font-size: .18rem;
                        letter-spacing: .0064rem;
                        text-decoration: underline;
                        color: #A68576;
                        cursor: pointer;
                    }
                }
            }
            .no-data{
                text-align: center;
                margin-top: 2.6rem;
                margin-bottom: 3rem;
                img{
                    width: 2.36rem;
                    height: 2.54rem;
                }
            }
        }
        .ant-pagination{
            text-align: center;
        }
    }
}

.list-page-ipad{
    .list-content{
        .content-title{
            .list-title{
                top: -1.18rem;
                left: 0.75rem;
                background-color: white;
                font-size: .95rem;
                line-height: 1.15rem;
            }
        }
    }
    .list-banner{
        padding-top: 3.85rem;
        height: 6.4rem;
        h4{
            font-size: .95rem;
            line-height: 1.15rem;
        }
    }
    .layout-content{
        width: 100%;
        padding-bottom: 4rem;
        .content-title{
            width: auto;
        }
        .content-list{
            padding: 0 .875rem;
            width: 100%;
            .list-item{
                .text-title{
                    -webkit-line-clamp: 1 !important;
                }
            }
        }
        .ant-pagination{
            .ant-pagination-prev,.ant-pagination-next{
                width: .8rem;
                height: .8rem;
                line-height: .8rem;
                .anticon-left,.anticon-right{
                    font-size: .3rem;
                }
            }
            .ant-pagination-item{
                width: .8rem;
                height: .8rem;
                line-height: .8rem;
                a{
                    font-size: .35rem;
                }
            }
        }
    }
}

.market-ipad{
    .layout-content{
        .content-list{
            .list-item{
                .img{
                    width: 6.425rem;
                    height: 4.3rem;
                }
                .list-text{
                    margin-left: 1.175rem;
                    .text-title{
                        font-size: .45rem;
                        line-height: .625rem;
                        letter-spacing: .016rem;
                    }
                    .text-desc,.text-down{
                        font-size: .3rem;
                        line-height: .425rem;
                        letter-spacing: .0107rem;
                    }
                }
            }
        }
    }
}

.specialR-ipad{
    .layout-content{
        .list-content{
            .list-item{
                .img{
                    width: 6.45rem;
                    height: 3.575rem;
                }
                .list-text{
                    margin-left: 1.175rem;
                    .text-title{
                        font-size: .45rem;
                        line-height: .625rem;
                        letter-spacing: .016rem;
                    }
                    .text-desc,.text-down{
                        font-size: .3rem;
                        line-height: .425rem;
                        letter-spacing: .0107rem;
                    }
                }
            }
        }
    }
}

.list-page-mobile{
    .list-banner{
        padding-top: 0;
        height: 3.9375rem;
        margin-bottom: 2.625rem;
        margin-top: 2.75rem;
        h4{
            @include contentSubtitle();
            line-height: 3.9375rem;
        }
        p{
            width: auto;
            @include contentFont();
        }
    }
    .list-content{
        .content-title{
            width: 20.625rem;
            margin: 0 auto;
            .list-title{
                @include contentSubtitle();
                padding: 0 .5rem;
                left: 2.875rem;
                top: -2.4375rem;
            }
        }
        .content-list{
            width: 20.625rem;
            margin-top: 1.0625rem;
            .list-item{
                display: block;
                height: 21.25rem;
                position: relative;
                margin-bottom: 2.5rem;
                .img{
                    width: 100%;
                    height: 10.3125rem;
                    margin-bottom: 1.4375rem;
                }
                .list-text{
                    width: 100%;
                    margin-left: 0;
                    position: static;
                    .text-title{
                        letter-spacing: .0444rem;
                        line-height: 1.75rem;
                        font-size: 1.25rem;
                        margin-bottom: .3125rem;
                    }
                    .text-desc{
                        font-size: .875rem;
                        letter-spacing: .0313rem;
                        line-height: 1.25rem;
                        -webkit-line-clamp: 3;
                    }
                    .text-down{
                        @include contentFont();
                    }
                }
            }
        }
    }
    .ant-pagination{
        font-size: .875rem;
        margin-bottom: 1.25rem;
        .ant-pagination-prev{
            width: 2rem;
            height: 2rem;
            .ant-pagination-item-link{
                font-size: .75rem !important;
            }
        }
        .ant-pagination-next{
            width: 2rem;
            height: 2rem;
            .ant-pagination-item-link{
                font-size: .75rem !important;
            }
        }
        .ant-pagination-item{
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
        }
    }
}





