.CustomMap{
    width: 10.3rem;
    height: 5.15rem;
    margin: 0 auto;

    display: flex;
    flex-wrap: nowrap;

    text-align: left;
    font-size: .18rem;
    color: #1F1E2E;
    line-height: .25rem;
    letter-spacing: .0064rem;

    overflow: hidden;
    background: #FFFFFF;
    border-radius: .02rem;
    box-shadow: 0rem 0rem .24rem -0.08rem rgba(0,0,0,0.5);
    .detail{
        width: 4.3rem;
        padding: 1.07rem .63rem;
        
        li{
            display: flex;
            margin-bottom: .5rem;
            &:last-child{
                margin: 0;
            }
            img{
                margin-right: .27rem;
            }
        }
    }
    img{
        width: .25rem;
        height: .25rem;
    }
}