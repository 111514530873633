.Structured{
    .Insights-schema{
        // .line{
        //     width: 2rem;
        //     height: .2rem;
        //     background-color: #96837e;
        // }
        height: 10rem;
        background-image: url('../../images/bg/bg-structured-banner.png');
        background-size: contain;
        .content-b{
            padding-top: 3.8rem;
        }
        .insight-large-title{
            color: white;
            margin-bottom: .43rem;
        }
        .insight-desc{
            width: 8.67rem;
            font-size: .18rem;
            line-height: .25rem;
            color: white;
            text-align: center;
        }
    }
    .Insights-play{
        padding-top: 1.04rem;
        .titleAndDesc{
            text-align: center;
            .title{
                font-size: .46rem;
                line-height: .73rem;
                font-weight: 600;
            }
            .desc{
                font-size: .18rem;
                line-height: .25rem;
                margin-top: .05rem;
            }
        }
        .play-content{
            width: 11.71rem;
            margin: .46rem auto 1.4rem;
            .list{
                display: flex;
                padding: 0 .24rem;
                justify-content: space-between;
                .list-item{
                    text-align: center;
                    cursor: pointer;
                    .icon{
                        width: .74rem;
                        height: .74rem;
                        text-align: center;
                        line-height: .74rem;
                        font-size: .2rem;
                        line-height: .74rem;
                        color: #36A1FD;
                        background-color: #f3f9ff;
                        border-radius: 50%;
                        margin: 0 auto;
                    }
                    .text{
                        font-size: .18rem;
                        margin-top: .08rem;
                    }
                }
                .list-item-active{
                    .icon{
                        color: white;
                        background-color: #36A1FD;
                    }
                }
            }
            .video-content{
                margin-top: .5rem;
            }
        }
    }
    .Insights-faq{
        width: 9.85rem;
        margin: 0 auto;
        .faq-title{
            text-align: center;
            line-height: .4rem;
            font-size: .28rem;
            font-weight: bold;
            letter-spacing: .01rem;
            margin-bottom: .3rem;
        }
        .ant-collapse{
            .collapse-icon{
                width: .16rem;
                height: .16rem;
            }
            .question-icon{
                width: .16rem;
                height: .16rem;
                margin-right: .14rem;
            }
            .ant-collapse-item{
                border-bottom: 1px solid #CBCBCB;
                margin-bottom: .26rem;
                padding-bottom: .16rem;
                .ant-collapse-header{
                    border-radius: 0px !important;
                }
                &:last-child{
                    display: none;
                }
            }
        }
    }
    .Insights-risk{
        width: 8.55rem;
        margin: 0 auto;
        .risk-title{
            font-size: .32rem;
            line-height: .47rem;
            letter-spacing: 1px;
            color: #1F1E2E;
            font-weight: bold;
            text-align: center;
            margin-bottom: .47rem;
        }
        .risk-content{
            .list-item{
                margin-bottom: .2rem;
                .item-title{
                    font-size: .17rem;
                    line-height: .21rem;
                    color: #A58476;
                    margin-bottom: .1rem;
                }
                .item-desc{
                    font-size: .14rem;
                    line-height: .21rem;
                    color: #666666;
                }
            }
        }
    }
}

.InsightIpad{
    .Insights-risk{
        width: 20rem;
        .risk-title{
            font-size: .6rem;
            line-height: .8rem;
            margin-bottom: 1rem;
        }
        .risk-content{
            .list-item{
                margin-bottom: .5rem;
                .item-title{
                    font-size: .4rem;
                    line-height: .6rem;
                }
                .item-desc{
                    font-size: .3rem;
                    line-height: .45rem;
                    margin-top: .25rem;
                }
            }
        }
    }
}

.InsightsMobile{
    .Insights-risk{
        width: 20.625rem;
        .risk-title{
            font-size: 1.125rem;
            line-height: 1.5625rem;
        }
        .risk-content{
            .list-item{
                margin-bottom: .75rem;
                .item-title{
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
                .item-desc{
                    font-size: .75rem;
                    line-height: 1.125rem;
                    margin-top: .375rem;
                }
            }
        }
    }
}




