@mixin insight-title{
    font-size: 1.75rem;
    line-height: 4.5625rem;
    letter-spacing: .0625rem;
}
@mixin contentFont {
    font-size: .75rem;
    line-height: 1.0625rem;
    letter-spacing: .0269rem;
}
@mixin contentSubtitle{
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: .04rem;
}
@mixin titleFont{
    font-size: 1.75rem;
    letter-spacing: .0625rem;
    line-height: 2.5rem;
}

.Insights {
    background-color: white;
    padding-bottom: 200px;
    .line {
        width: .8rem;
        height: .1rem;
        margin: 0 auto;
        border-radius: .06rem;
        background-color: #A68576;
    }
}

.Insights-schema {
    // .content-t {
    //     width: 100%;
    //     height: .88rem;
    //     background-color: rgba(0, 46, 83, 0.84);
    // }

    .content-b {
        padding-top: 1.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .insight-content {
            width: 9.4rem;
            margin-top: .21rem;
            text-align: center;
        }
    }
}

.Insights-view {
    .content {
        width: 15rem;
        padding: 1.2rem 0 1.1rem 0;
    }

    .content-t {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            color: rgba(166, 133, 118, 1);
            align-self: flex-end;
            margin-bottom: .3rem;
        }

        a::after {
            content: ' ';
            display: inline-block;
            width: .16rem;
            height: .16rem;
            background: url(../../images/icon/icon-arrow-right.png) no-repeat;
            background-size: .16rem;
            vertical-align: middle;
            margin-left: .02rem;
        }
    }

    .content-b {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        >img{
            margin: 0 auto;
            width: 2rem;
            height: 2.15rem;
        }
        .report-empty-item{
            background-color: rgba($color:  #002E53, $alpha: 0.9);
            p{
                text-align: center;
                line-height: 4rem;
                font-size: .24rem;
                color: white;
            }
        }
    }

}

.Insights-report {
    .content {
        width: 15rem;
        padding: 1.2rem 0 1.1rem 0;
    }

    .content-t {
        display: flex;
        flex-direction: column;
        align-items: center;

        a {
            color: rgba(166, 133, 118, 1);
            align-self: flex-end;
            margin-bottom: .3rem;
        }

        a::after {
            content: ' ';
            display: inline-block;
            width: .16rem;
            height: .16rem;
            background: url(../../images/icon/icon-arrow-right.png) no-repeat;
            background-size: .16rem;
            vertical-align: middle;
            margin-left: .02rem;
        }
    }

    .content-b {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        >img{
            margin: .5rem auto 0;
            width: 2rem;
            height: 2.15rem;
        }
        .report-empty-item{
            background-color: rgba($color:  #002E53, $alpha: 0.9);
            p{
                text-align: center;
                line-height: 4rem;
                font-size: .24rem;
                color: white;
            }
        }
        
    }
}



.Insights-education {
    .content {
        width: 15rem;
        padding: .7rem 0;
        display: flex;
        align-items: center;
    }

    .content-l {
        width: 5.86rem;

        .insight-large-title {
            margin-bottom: .4rem;
        }

        .insight-content {
            margin-bottom: .1rem;
        }
    }

    .content-r {
        flex: 1;
        padding-left: 1.14rem;
    }

}


.insight-large-title {
    font-size: .6rem;
    font-weight: 500;
    letter-spacing: .02rem;
    line-height: .73rem;
}

.insight-title {
    font-size: .32rem;
    font-weight: 500;
    line-height: .45rem;
    letter-spacing: .0114rem;
}

.insight-subtitle {
    font-size: .24rem;
    font-weight: 500;
    letter-spacing: .0086rem;
    line-height: .33rem;
}

.insight-desc {
    font-size: .26rem;
    letter-spacing: .0093rem;
    line-height: .4rem;
}

.insight-content {
    font-size: .18rem;
    letter-spacing: .0064rem;
    line-height: .25rem;
}

.content-b{
    .ReportItem{
        .content-container{
            .title{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                width: auto;
            }
            .insight-content{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 8;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.insights-Special_report{
    .content-b{
        flex-wrap: nowrap;
        justify-content: space-between;
        .ReportItem{
            width: 4.7rem;
            height: 4.87rem;
            background: #0F385A;
            .mask{
                display: none;
            }
            .bg{
                z-index: 3;
                height: 3.2rem;
                transition-property: height;
                transition-duration: 0.3s;
            }
            .content-container{
                background: #0F385A;
                padding-top: 3.32rem;
                transition-property: padding-top;
                transition-duration: 0.3s;
                height: calc(100% - .69rem);
                overflow: hidden;
            }
            .download-btn{
                width: 3.62rem;
                height: .49rem;
                border: .01rem solid #fff;
                font-size: .16rem;
                text-align: center;
                line-height: .49rem;
                position: absolute;
                bottom: .2rem;
                color: white;
                left: .54rem;
            }
            .title{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin-bottom: .18rem;
            }
            .insight-content{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
            &:hover{
                .bg{
                    // height: 0;
                    display: none;
                }
                .content-container{
                    padding-top: .4rem;
                    .title{
                        -webkit-line-clamp: 1;
                    }
                    .insight-content{
                        -webkit-line-clamp: 10;
                    }
                }
            }
        }
    }
    .onlyOne{
        justify-content: center;
        .ReportItem{
            margin-right: .45rem;
        }
    }
}



.InsightPc{
    

}

.InsightIpad{
    .line{
        width: 1.275rem;
        height: .15rem;
        border-radius: .1rem;
    }
    .layout-content{
        width: 100%;
        padding: 1.8rem 32px 0;
        .Insights-schema{
            .content{
                .content-t{
                    display: none;
                }
                .content-b{
                    .insight-large-title{
                        font-size: .95rem;
                        line-height: 1.15rem;
                    }
                    .insight-content{
                        width: 15rem;
                        font-size: .3rem;
                        line-height: .45rem;
                    }
                }
            }
        }
        .insights-item{
            .insight-title{
                font-size: .6rem;
                line-height: .825rem;
                letter-spacing: .0215rem;
            }
            .content-t{
                .insight-content{
                    font-size: .3rem;
                    line-height: .45rem;
                    &::after{
                        width: .3rem;
                        height: .3rem;
                        background-size: contain;
                    }
                }
            }
        }
        .insights-Special_report{
            .content{
                width: 100%;
                // padding: 0;
                
                .content-b{
                    .ReportItem{
                        width: 7.5rem;
                        height: 7.75rem;
                        img{
                            width: 7.5rem;
                            height: 5rem;
                        }
                        .content-container{
                            padding-top: 5.3rem;
                            height: calc(100% - 1.125rem);
                            &:hover{
                                padding-top: .65rem;
                            }
                            .title{
                                font-size: .35rem;
                                line-height: .5rem;
                            }
                            .insight-content{
                                font-size: .3rem;
                                line-height: .45rem;
                            }
                        }
                        .download-btn{
                            width: 5.75rem;
                            height: .75rem;
                            left: .85rem;
                            bottom: .325rem;
                            font-size: .3rem;
                            line-height: .75rem;
                        }
                    }
                    .report-empty-item{
                        p{
                            line-height: 7.75rem;
                        }
                    }
                }
            }
        }
        .insights-Research_report,.insights-Market_review{
            .content{
                width: 100%;
                .content-b{
                    .ReportItem{
                        width: 11.675rem;
                        height: 6.4rem;
                        .content-container{
                            padding: .925rem .7rem;
                            .title{
                                font-size: .4rem;
                                line-height: .6rem;
                                font-family: SourceHanSansCN-Bold;
                                width: 7rem;
                            }
                            .insight-content{
                                font-size: .3rem;
                                line-height: .45rem;
                                width: 7rem;
                            }
                        }
                        .mask{
                            img{
                                width: 2rem;
                                height: 2rem;
                            }
                        }
                    }
                    .report-empty-item{
                        p{
                            line-height: 6.4rem;
                        }
                    }
                }
            }
        }
        .Insights-education{
            .content{
                width: 100%;
                .content-l{
                    width: 10.375rem;
                    .insight-large-title{
                        font-size: .95rem;
                        line-height: 1.15rem;
                        letter-spacing: .0343rem;
                    }
                    .insight-content{
                        width: 9.375rem;
                        font-size: .3rem;
                        line-height: .45rem;
                    }
                }
                .content-r{
                    .InvestorEducationItem{
                        img{
                            width: .75rem;
                            height: .75rem;
                        }
                        .content-container{
                            padding-left: 1.275rem;
                            margin: .8rem 0;
                            .title{
                                font-size: .5rem;
                                line-height: .7rem;
                            }
                            .insight-desc{
                                font-size: .4rem;
                                line-height: .6rem;
                            }
                        }
                    }
                }
            }
        }
    }
}


.InsightsMobile{
    .line{
        width: 3rem;
        height: .375rem;
        border-radius: .375rem;
        margin-bottom: 1rem;
    }
    .Insights-schema{
        .content{
            .content-t{
                height: 0;
            }
            .content-b{
                width: 20.9375rem;
                margin: 0 auto 3.125rem;
                padding-top: 5.0625rem;
                .insight-content{
                    width: auto;
                    @include contentFont();
                    margin-top: .125rem;
                    text-align: center
                    
                }
                .insight-large-title{
                    @include insight-title();
                }
            }
        }
    }
    .insights-item{
        padding-top: 0;
    }
    .Insights-view,.Insights-report,.Insights-report,.Insights-education{
        margin-bottom: 3.125rem;
        .content{
            padding: 0;
            width: 20.9375rem;
            margin: 0 auto;
            .content-t{
                .insight-title{
                    @include contentSubtitle();
                }
                .insight-content{
                    @include contentFont();
                    margin-bottom: .3125rem;
                    &::after{
                        width: .875rem;
                        height: .875rem;
                        background-size: .875rem;
                    }
                }
            }
            .content-b{
                .ReportItem{
                    height: 20.625rem;
                    margin-bottom: .3125rem;
                    width: 23.4375rem;
                    .mask{
                        // display: none;
                    }
                    .content-container{
                        padding: 3.875rem 1.125rem;
                        .title{
                            @include contentSubtitle();
                            -webkit-line-clamp: 2;
                            height: auto;
                            margin-bottom: 1.875rem;
                        }
                        .insight-content{
                            @include contentFont();
                            -webkit-line-clamp: 5;
                            
                        }
                    }
                }
                .noData{
                    width: 200px;
                    height: 12.5rem;
                }
                .report-empty-item{
                    p{
                        line-height: 20.625rem;
                    }
                }
                .report-empty-mobile{
                    display: none;
                }
            }
        }
    }
    .Insights-view{
        margin-top: 4.4375rem;
    }
    .Insights-education{
        width: 20.9375rem;
        margin: 0 auto;
        padding-bottom: 1.875rem;
        .content{
            display: block;
            .content-l{
                width: auto;
                margin-bottom: 2.625rem;
                .insight-large-title{
                    font-size: 1.75rem;
                    letter-spacing: .0625rem;
                    line-height: 4.5625rem;
                    margin-bottom: 0;
                }
                .insight-content{
                    @include contentFont();
                }
            }
            .content-r{
                padding-left: 0;
                .InvestorEducationItem{
                    // display: block;
                    align-items: flex-start;
                    margin-bottom: 3.5625rem;
                    img{
                        width: 2.1875rem;
                        height: 2.1875rem;
                        margin: .3125rem .625rem 0 0;
                    }
                    .content-container{
                        margin: 0;
                        padding: 0;
                        .title{
                            @include contentSubtitle();
                            line-height: 2.8125rem;
                        }
                        .insight-desc{
                            @include contentFont();
                        }
                    }
                }
            }
        }
    }
    .insights-Special_report{
        .content{
            .content-b{
                flex-wrap: wrap;
                .ReportItem{
                    width: 20.9375rem;
                    .download-btn{
                        width: 15.9375rem;
                        height: 2.1875rem;
                        line-height: 2.1875rem;
                        left: 2.375rem;
                        bottom: .875rem;
                        font-size: .75rem;
                    }
                    img{
                        height: 14.0625rem;
                    }
                    .content-container{
                        height: auto;
                        padding-bottom: 0;
                        padding-top: 13.625rem;
                        .title{
                            font-size: 1rem;
                            -webkit-line-clamp: 1;
                            margin-bottom: 0;
                            margin-top: .625rem;
                        }
                        .insight-content{
                            -webkit-line-clamp: 1;
                        }
                    }
                }
            }
        }
    }
}
