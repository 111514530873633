.Login{
    background-color: #ccc;
    text-align: center;
    padding-bottom: 100px;
    padding-top: 200px;
    .login-content{
        width: 350px;
        height: 400px;
        background-color: white;
        margin: 0 auto 0;
        padding-right: 25px;
        // .login-flex{
        //     display: flex;
        //     margin-top: 30px;
        //     span{
        //         width: 100px;
        //         text-align: left;
        //         line-height: 32px;
        //     }
        //     .ant-input{
        //         width: 200px;
        //     }
        //     .ant-input-password{
        //         .ant-input{
        //             width: 200px;

        //         }
        //     }
        // }
        // .login-username{

        // }
        // .login-pwd{
        //     margin-bottom: 50px;
        // }
        .ant-form{
            margin-top: 40px;
            .ant-form-item{
                .ant-form-item-control-input-content{
                    text-align: left;
                    .ant-btn{
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}
