@mixin contentFont {
    font-size: .75rem;
    line-height: 1.0625rem;
    letter-spacing: .0269rem;
}
@mixin contentSubtitle{
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: .04rem;
}

.About {
    padding-bottom: 200px;
    .line {
        width: .8rem;
        height: .1rem;
        margin: 0 auto;
        border-radius: .06rem;
        background-color: #A68576;
    }
}

.About-introduction {
    width: 100%;
    height: 12.03rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
    .left {
        width: 6.37rem;
        padding: 2.77rem .53rem 0 0;
        
    }

    .right {
        width: 55%;
        height: 100%;
        position: relative;

        .bg {
            width: 100%;
            height: 9.21rem;
            background-image: url("../../images/bg/bg-banner-about.png");
            background-size: cover;
        }

        .v-line {
            background-color: rgba(0, 46, 83, 1);
            width: .32rem;
            height: 3.98rem;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        .presence {
            width: 6.87rem;
            padding: .35rem 0 0 .6rem;
            .about-content{
                width: 6.27rem;
            }
            .about-subtitle {
                line-height: .9rem;
            }
        }
    }

}

.About-values {
    padding-top: 1rem;
    .deline{
        margin-bottom: .59rem;
        height: .08rem;
        width: 1.56rem;
    }
    .content {
        width: 15rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .value-one {
        align-self: flex-start;
    }

    .value-two {
        margin-top: .62rem;
        align-self: flex-end;
    }

    .value-three {
        margin-top: 1.12rem;
        align-self: flex-start;
    }

    .value-four {
        margin-top: .72rem;
        align-self: flex-end;
    }
    .value{
        
    }
}

.AboutPc,.AboutIpad{
    .About-values{
        .value-one{
            width: 15rem;
            height: 6rem;
            padding-top: 1.65rem;
            margin-top: 2rem;
            background-image: url('../../images/img/img-about-bg1.png');
            background-repeat: no-repeat;
            background-position: 0 0;
            background-size: 15rem 6rem;
            .ValueItem{
                width: 9.54rem;
                .value-title{
                    margin-bottom: 0.64rem;
                }
            }
        }
        .value-two{
            width: 15rem;
            height: 7.34rem;
            padding-left: 5.1rem;
            padding-top: 1.27rem;
            background-image: url('../../images/img/img-about-bg2.png');
            background-repeat: no-repeat;
            background-size: 5.56rem 7.34rem;
            padding-right: 0;
            .ValueItem{
                .about-content{
                    letter-spacing: 0.01rem;
                }
            }
        }
        .value-three{
            width: 15rem;
            height: 7.03rem;
            padding-top: 3.34rem;
            background-image: url('../../images/img/img-about-bg3.png');
            background-repeat: no-repeat;
            background-size: 5.5rem 7.03rem;
            background-position: 8.33rem 0;
            .ValueItem{
                width: 8.8rem;
            }
        }
        .value-four{
            width: 15rem;
            height: 6.59rem;
            padding-left: 4.7rem;
            background-image: url('../../images/img/img-about-bg4.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .ValueItem{
                width: 10rem;
                margin-top: 3.48rem;
            }
        }
        .value{
            margin-top: 1.95rem;
            &:nth-child(2n){
                padding-right: .04rem;
            }
            &:nth-child(2n+1){
                padding-left: .33rem;
            }
        }
    }
}

.About-wealth {
    margin: 1.93rem 0rem 1.82rem 0rem;
    .deline{
        margin: 0 auto;
        border-radius: .06rem;
        margin-bottom: .76rem;
    }
    .content {
        width: 11.25rem;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: .76rem;
    }

    .center-title {
        text-align: center;
        font-size: .42rem;
        line-height: .6rem;
        letter-spacing: .0143rem;
    }
}

.About-team {
    background-image: url("../../images/bg/bg-team.png");
    width: 100%;
    height: 8.5rem;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.06rem;
    overflow: hidden;
    margin-bottom: 2rem;
    .content {
        width: 15rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
}

.About-investment{
    .content{
        margin-top: .76rem;
        width: 11.56rem;
        height: 4.28rem;
        background-color: rgba($color: #002E53, $alpha: .75);
        margin: .76rem auto 5.36rem;
        color: white;
        padding: .99rem .58rem .69rem;
        .content-title{
            font-size: .36rem;
            color: #F5CDBD;
            letter-spacing: .01rem;
            margin-bottom: .28rem;
        }
        .content-desc{
            font-size: .18rem;
            line-height: .28rem;
            letter-spacing: 1px;
        }
        .learn-more{
            text-align: right;
            margin-top: .72rem;
            a{
                font-size: .18rem;
                color: #F6CEBE;
                line-height: .22rem;
                img{
                    width: .18rem;
                    height: .18rem;
                    margin-left: .11rem;
                }
            }
        }
    }
}

.About-news {
    margin: 1.03rem 0;
    padding-top: 1rem;
    .view-all{
        text-align: right;
        font-size: .18rem;
        a{
            color: #A68576;
            span{
                margin-left: .05rem;
            }
        }
    }
    .content {
        width: 13.22rem;
        .no-data{
            text-align: center;
            margin-top: 1rem;
            img{
                width: 2rem;
                height: 2.15rem;
            }
        }
    }
}

.about-color-title-left {
    color: #17283D;
    font-size: .58rem;
    font-weight: 500;
    letter-spacing: .02rem;
    line-height: .87rem;
    font-family: SourceHanSansCN-Bold;
}

.about-color-title-right {
    color: #17283D;
    font-size: .94rem;
    font-weight: 500;
    letter-spacing: .04rem;
    line-height: .87rem;
    font-family: SourceHanSansCN-Bold;
}

.about-large-title {
    font-size: .6rem;
    font-weight: bold;
    letter-spacing: .0214rem;
    line-height: .9rem;
}

.about-title {
    font-size: .42rem;
    font-weight: 800;
    line-height: .63rem;
    letter-spacing: .015rem;
    text-align: center;
}

.about-subtitle {
    font-size: .26rem;
    font-weight: 500;
    letter-spacing: .0093rem;
    line-height: .39rem;
}

.title{
    font-size: .6rem;
    font-weight: bold;
}

.deline{
    width: .58rem;
    height: .06rem;
    background-color: #A58476;
    margin-bottom: .08rem;
}

.about-content-title{
    font-size: .32rem;
    color: #1F1E2E;
    line-height: .45rem;
    letter-spacing: .01rem;
    margin-bottom: .12rem;
}

.about-content {
    font-size: .2rem;
    letter-spacing: .0064rem;
    line-height: .28rem;
    margin-bottom: .26rem;
    color: #666666;
    .about-content-titleBox{
        display: inline-block;
    }
    .about-content-word{
        font-size: .32rem;
        color: #1F1E2E;
        display: inline-block;
        width: .3rem;
    }
}

.about-desc {
    font-size: .2rem;
    letter-spacing: .0071rem;
    line-height: .28rem;
}

.AboutIpad{
    // .layout-content {
    //     max-width: 1025px;
    //     margin: 0 auto;
    //     .About-values{
    //         .content{
    //             width: 1025px;
    //             .value-one{
    //                 width: 1025px;
    //                 background-size: 1025px 600px;
    //                 .ValueItem{
    //                     width: 638px;
    //                 }
    //             }
    //             .value-two{
    //                 width: 1025px;
    //             }
    //             .value-three{
    //                 width: 1025px;
    //                 background-position: 600px 100px;
    //                 background-size: 400px 550px;
    //                 .ValueItem{
    //                     width: 600px;
    //                 }
    //             }
    //             .value-four{
    //                 width: 1025px;
    //                 padding-left: 350px;
    //                 .ValueItem{
    //                     width: 600px;
    //                 }
    //             }
    //         }
    //     }
    //     .About-team{
    //         .content{
    //             width: 1025px;

    //         }
    //     }
    //     .About-news{
    //         .content{
    //             width: 100%;
    //             .NewsItem{
    //                 .news-container{
    //                     width: 340px;
    //                     .news-title{
    //                         width: 340px;
    //                         .news-content{
    //                         width: 340px;

    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }
    // .About-introduction{
    //     .left{
    //         padding-left: 20px;
    //     }
    // }
    .about-large-title{
        font-size: .8rem;
        line-height: 1.2rem;
    }
    .about-subtitle{
        line-height: 1.2rem;
        font-size: .8rem;
    }
    .about-content{
        font-size: .3rem;
        line-height: .4rem;
    }
    .about-title{
        font-size: .6rem;
        line-height: .8rem;
    }
    .line{
        width: 1.8rem;
        height: 0.16rem;
        border-radius: 0.08rem;
    }
    .deline{
        width: 1.58rem;
        height: .12rem;
    }
    .about-content-titleBox{
        .about-content-word{
            width: .3rem;
        }
    }
    .About-introduction{
        width: 100%;
        padding: 0 1rem;
        height: 14.5rem;
        .left{
            width: 45%;
        }
        .right{
            padding-top: 1.8rem;
            .presence{
                width: auto;
                .about-content{
                    width: 100%;
                }
            }
            .v-line{
                height: 4.2rem;
            }
        }
    }
    .layout-content{
        width: 100%;
        padding: 0 1rem;
        .About-values{
            .content{
                width: auto;
                .value{
                    width: 100%;
                    height: 9rem;
                    .about-color-title-right{
                        font-size: 1.28rem;
                    }
                    .about-color-title-left{
                        font-size: 0.78rem;
                    }
                }
                .value-one{
                    background-size: 23.5rem 9.4rem;
                    padding-top: 3rem;
                    .ValueItem{
                        width: 15.54rem;
                        .value-title{
                            margin-bottom: 1rem;
                        }
                    }
                }
                .value-two{
                    background-size: 6.3rem 8.34rem;
                    padding-left: 6.5rem;
                }
                .value-three{
                    background-position: right 0;
                    background-size: 7rem 9rem;
                    padding-top: 4.8rem;
                    .ValueItem{
                        width: 16rem;
                    }
                }
                .value-four{
                    background-size: 23.5rem 9rem;
                    padding-left: 7.7rem;
                    .ValueItem{
                        width: 15rem;
                        margin-top: 4.48rem;
                    }
                }
            }
        }
        .About-wealth{
            .about-subtitle{
                font-size: 0.6rem;
                line-height: 0.8rem;
            }
            .content{
                width: 100%;
                .WealthItem{
                    width: 10.9rem;
                    height: 5.45rem;
                    padding: .825rem .8rem;
                    .background{
                        width: 3.825rem;
                        height: 0.36rem;
                        top: 1.45rem;
                        left: .575rem;
                    }
                    .item-title{
                        font-size: .75rem;
                        line-height: 1.15rem;
                    }
                    .item-content{
                        margin-top: 1.52rem;
                        color: #1F1E2E;
                        font-size: .4rem;
                        line-height: .6rem;
                    }
                    &:hover{
                        .item-content{
                            color: white;
                        }
                    }
                    .item-icon{
                        width: 3.45rem;
                        height: 3.45rem;
                    }
                }
            }
        }
        .About-news{
            .content{
                width: 100%;
                .view-all{
                    font-size: .325rem;
                }
                .NewsItem{
                    .img-box{
                        width: 11.25rem;
                        height: 6.25rem;
                    }
                    .news-container{
                        margin-left: 1.25rem;
                        max-width: none;
                        width: 10.45rem;
                        .news-title{
                            font-size: .45rem;
                            line-height: .6rem;
                        }
                    }
                }
            }
        }
    }
    .About-team{
        padding: 1.675rem 1.8rem 0;
        height: 13.85rem;
        .content{
            width: 100%;
            .TeamItem{
                width: 5.125rem;
                height: 7.325rem;
                &:hover{
                    width: 8.025rem;
                    height: 9.925rem;
                }
                .about-subtitle{
                    font-size: .4rem;
                    line-height: .6rem;
                }
                .about-content{
                    font-size: .3rem;
                    line-height: .425rem;
                }
                .about-desc-group{
                    .about-desc{
                        font-size: .3rem;
                        line-height: .425rem;
                    }
                }
            }
        }
    }
    .About-investment{
        .content{
            width: 18rem;
            height: 7rem;
            .content-title{
                font-size: .4rem;
                line-height: .6rem;
            }
            .content-desc{
                font-size: .3rem;
                line-height: .45rem;
            }
            .learn-more{
                margin-top: 3rem;
                a{
                    font-size: .3rem;
                    line-height: .45rem;
                }
                img{
                    width: .3rem;
                    height: .3rem;
                }
            }
        }
    }
}

.AboutMobile{
    background-color: white;
    .about-title{
        font-size: 2rem;
        line-height: 2.8125rem;
    }
    .view-all{
        font-size: 1.125rem;
    }
    .line{
        width: 5rem;
        height: .625rem;
        border-radius: .375rem;
    }
    .About-introduction{
        display: block;
        height: auto;
        margin-bottom: 6.8125rem;
        .left{
            width: 100%;
            padding: 2.75rem 0 0;
            margin: 0 auto;
            .about-large-title{
                font-size: 1.375rem;
                line-height: 2.5rem;
                letter-spacing: .0625rem;
                width: 10rem;
                display: inline-block; 
                margin: 4.6875rem 0 4.6875rem 1.6875rem;
            }
            .left-top{
                width: 100%;
                position: relative;
            }
            .bg{
                background-image: url('../../images/bg/bg-banner-about-mobile.png');
                background-size: 10.375rem 10.6875rem;
                background-repeat: no-repeat;
                width: 10.375rem;
                height: 10.6875rem;
                display: inline-block;
                // background-position: right top;
                position: absolute;
                top: 0;
                right: 0;
            }
            .about-content{
                padding: 0 1.4375rem 0 1.6875rem;
                font-size: .75rem;
                letter-spacing: .0269rem;
                line-height: 1.0625rem;
                margin-bottom: .625rem;
            }
        }
        .right{
            padding: 0 1.4375rem 0 0;
            width: auto;
            .v-line{
                left: 0;
                top: 0;
                width: 1.125rem;
                height: 15.4375rem;
            }
            .presence{
                padding: 2.5rem 1.1875rem 0 3.4375rem;
                width: 100%;
                .about-subtitle{
                    font-size: 1.25rem;
                    letter-spacing: .0437rem;
                    line-height: 1.75rem;
                    margin-bottom: 1.25rem;
                }
                .about-content{
                    font-size: .75rem;
                    letter-spacing: .0269rem;
                    line-height: 1.0625rem;
                    width: 17.9375rem;
                }
            }
        }
    }
    .About-values{
        width: 20.9375rem;
        margin: 0 auto;
        .about-title{
            font-size: 1.125rem;
            line-height: 2.8125rem;
            letter-spacing: .0712rem;
        }
        .content{
            width: 100%;
            .value{
                width: 100%;
                margin-top: 2.5625rem;
                .imgs{
                    text-align: center;
                }
                .about-color-title-left{
                    font-size: 1rem;
                    line-height: 1.75rem;
                }
                .about-color-title-right{
                    font-size: 1.625rem;
                    line-height: 1.75rem;
                }
                .about-content{
                    @include contentFont();
                    margin-top: .25rem;
                    margin-bottom: .625rem;
                }
                .ValueItem{
                    .deline{
                        width: 5.125rem;
                        height: .1875rem;
                        margin-bottom: .3125rem;
                    }
                }
            }
            .value-one{
                width: 100%;
                img{
                    width: 20rem;
                    height: 8.75rem;
                    margin-bottom: 1.875rem;
                }
            }
            .value-two{
                margin-top: 2.5625rem;
                .imgs{
                    text-align: center;
                }
                img{
                    width: 6.8125rem;
                    height: 8.75rem;
                    margin: 0 auto 30px;
                }
                .ValueItem{
                    .about-content{
                        .about-content-word{
                            font-size: .875rem;
                            width: 1rem;
                        }
                        .about-content-titleBox{
                            display: block;
                            font-weight: 500;
                            margin-bottom: .3125rem;
                        }
                    }
                }
            }
            .value-three{
                img{
                    width: 6.8125rem;
                    height: 8.75rem;
                    margin: 0 auto 1.875rem;
                }
            }
            .value-four{
                img{
                    width: 20rem;
                    height: 8.75rem;
                    margin-bottom: 1.875rem;
                }
            }
        }
    }
    .About-wealth{
        width: 20.625rem;
        margin: 3.5rem auto 0;
        .center-title{
            @include contentSubtitle();
            margin-bottom: .9375rem;
        }
        .content{
            width: 100%;
            .WealthItem{
                padding: 1.625rem 4.5625rem .625rem 1.5625rem;
                // background-color: #002948;
                color: white;
                width: 20.625rem;
                height: 10.3125rem;
                margin-bottom: 2.5rem;
                .about-subtitle{
                    @include contentSubtitle();
                    margin-bottom: .625rem;
                    color: #1F1E2E;
                    font-size: 1.5rem
                }
                .about-content{
                    @include contentFont();
                    color: #1F1E2E;
                }
                .item-icon{
                    width: 6.4375rem;
                    height: 6.4375rem;
                }
                .item-content{
                    margin-top: 2.5rem;
                }
                .background{
                    width: 7.5rem;
                    height: .875rem;
                    border-radius: .75rem;
                    top: 2.75rem;
                    left: 1.125rem;
                }
                &:hover{
                    .about-subtitle{
                        color: white;
                    }
                    .about-content{
                        color: white;
                    }
                }
            }
        }
    }
    .About-team{
        margin-top: 1.5625rem;
        padding: 2.1875rem 1.375rem 0;
        display: block;
        height: auto;
        .content{
            width: 100%;
            margin-top: 1.4375rem;
            display: block;
            .TeamItem{
                width: 20rem;
                margin: 0 auto 3.125rem;
                background-color: #002E53;
                height: 26.5625rem;
                padding: 1.1875rem 1.1875rem .75rem 1.1875rem;
                &:hover{
                    height: 26.5625rem;
                    width: 20rem;
                }
                .about-personal-info{
                    display: flex;
                    width: 100%;
                    .circle{
                        width: 4rem;
                        height: 4rem;
                        margin: 0;
                        margin-right: .625rem;
                    }
                    .about-personal-job{
                        .about-subtitle{
                            @include contentSubtitle();
                            text-align: left;
                        }
                        .about-content{
                            text-align: left;
                            @include contentFont();
                            margin-bottom: 2rem;
                        }
                        .sub-line{
                            width: 6.25rem;
                            margin: .125rem auto 1.0625rem;
                        }
                    }
                }
                .arrow{
                    display: none;
                }
                .about-desc-group{
                    .about-desc{
                        display: block;
                        padding: 0;
                        @include contentFont();
                    }
                    height: 18.625rem;
                    overflow-y: scroll;
                    &::-webkit-scrollbar{
                        display: none;
                    }
                }
            }
        }
    }
    .About-investment{
        .content{
            width: 20.625rem;
            height: auto;
            padding: 1.1875rem;
            .content-title{
                font-size: 1.125rem;
                line-height: 1.5rem;
                margin-bottom: 1.25rem;
            }
            .content-desc{
                font-size: .75rem;
                line-height: 1.125rem;
            }
            .learn-more{
                a{
                    font-size: .75rem;
                    line-height: 1.125rem;
                }
                margin-top: 1.25rem;
                img{
                    width: .75rem;
                    height: .75rem;
                    margin-left: .5rem;
                }
            }
        }
    }
    .About-news{
        margin-top: 4.6875rem;   
        padding-bottom: 4.6875rem;
        .content{
            width: 20.625rem;
            .NewsItem{
                padding-top: 2.25rem;
                display: block;
                height: 21.5625rem;
                .img-box{
                    width: 100%;
                    height: 10.3125rem;
                    margin: 0 auto;
                    margin-bottom: 1.4375rem;
                }
                .news-container{
                    height: calc(100% - 10.3125rem);
                    margin-left: 0;
                    padding: 0 .3125rem;
                    width: 100%;
                    max-width: none;
                    .news-time{
                        left: .3125rem;
                    }
                    .about-subtitle{
                        font-size: 1.25rem;
                        letter-spacing: .0444rem;
                        line-height: 1.75rem;
                        margin-bottom: .3125rem;
                    }
                    .about-content{
                        font-size: .875rem;
                        letter-spacing: .0313rem;
                        line-height: 1.25rem;
                        -webkit-line-clamp: 3;
                    }
                }
            }
        }
    }
}