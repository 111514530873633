

.Index{
    background-color: white;
    .index-content{
        // font-size: .18rem;
        // letter-spacing: .0064rem;
        font-size: .26rem;
        line-height: .32rem;
        letter-spacing: 0.0093rem;
    }
    .index-subtitle{
        // font-size: .24rem;
        font-weight: 500;
        letter-spacing: .0086rem;
        font-size: .26rem;
        line-height: .28rem;
    }
    .index-title{
        // font-size: .32rem;
        font-weight: 500;
        // line-height: .45rem;
        letter-spacing: .0114rem;
        text-align: center;
        font-size: .54rem;
        line-height: .81rem;
    }
    .line{
        width: .8rem;
        height: .1rem;
        margin: 0 auto;
        border-radius: .06rem;
        background-color: #A68576;
        margin-top: .04rem;
    }
    .title{
        // font-size: .32rem;
        font-size: .42rem;
    }
    .content{
        margin-top: .8rem;
    }
    .Index-banner{
        height: auto;
        .banner-item{
            position: relative;
            // font-family: PingFang SC;
            cursor: pointer;
            .img{
                // height: 12rem;
                width: 100%;
                // height: calc(100vh - .88rem);
                // height: 12rem;
                height: auto;
                color: white;
                display: flex;
                justify-content: center;
                overflow: hidden;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .banner-label{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                color: white;
    
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .banner-title{
                    font-size: .6rem;
                    font-weight: 500;
                    line-height: .84rem;
                    letter-spacing: .0214rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        .no-banner{
            .img{
                img{
                    width: 10.36rem;
                    height: 4.1rem;
                    margin: auto;
                }
            }
        }
        .slick-dots{
            bottom: .6rem;
            .slick-active, li{
                width: .88rem ;
                height: .12rem;
                margin-inline: .125rem;
                border-radius: .12rem;
                overflow: hidden;
            }
            li > button{
                width: 100%;
                height: 100%;
            }
        }
    }
    .Index-about{
        background-color: #0B395D;
        .content{
            // max-width: 13.27rem;
            width: 15rem;
            margin: 0 auto;
            padding-block: .71rem;
            color: white;
            text-align: center;
            .index-content{
                margin-top: .12rem;
                p{
                    line-height: .31rem;
                }
            } 
        }
    }
    .Index-product{
        background-color: rgba($color: #0B395D, $alpha: .05);    
        padding-top: .8rem;
        padding-bottom: .5rem;
        .content{
            // max-width: 14.85rem;
            margin-top: 0;
            width: 15rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }
    .index-content{
        font-size: .2rem;
        
    }
    .Index-services{
        margin-top: 1.94rem;
        .title{
            margin-bottom: .04rem;
        }
        .content{
            // max-width: 13.14rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            & > div{
                margin-bottom: .2rem;
            }

        }
    }
    .Index-insight{
        margin-top: 1.8rem;
        .view-all{
            // max-width: 13.14rem;
            margin: 0 auto;
            text-align: right;
            a{
                color: #A68576;
                font-size: .18rem;
                span{
                    margin-left: .05rem;
                }
            }
        }
        .content{
            // margin-inline: auto;
    
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            .report-empty-item{
                background-color: rgba(0, 46, 83, 0.9);
                color: white;
                line-height: 5rem;
                text-align: center;
                font-size: .3rem;
            }
        }
        .no-data{
            text-align: center;
            img{
                margin: 0 auto;
                width: 2rem;
                height: 2.15rem;
            }
        }
    }
    .Index-education{
        margin: 1.9rem auto 5.89rem;
        .content{
            // width: 15.05rem;
            margin-inline: auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .EducationItem{
                .item-title{
                    font-family: SourceHanSansCN-Medium !important;
                }
            }
        }
    }
    .Index-contact{
        // margin-top: 1.94rem;
        width: 100%;
        height: 4.78rem;
        background-color: #F0F0F0;
        position: relative;
        .absolute{
            position: absolute;
            top: -3.95rem;
            // left: calc((100% - 11rem) / 2);
            // left: 25%;
            // top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    .layout-content{
        width: 15rem;
        margin: 0 auto;
    }
    // @media (min-width:1200px) and (max-width:1500px) {
    //     .layout-content{
    //         width: 1200px;
    //         .index-content{
    //             font-size: .24rem;
    //         }
    //     }
    // }
    // @media (min-width:1000px) and (max-width:1199px) {
    //     .layout-content{
    //         width: 1000px;
    //         .index-content{
    //             font-size: .24rem;
    //         }

    //     }
    // }
    // @media (min-width:766px) and (max-width:999px) {
    //     .layout-content{
    //         width: 740px;
    //         .index-content{
    //             font-size: .28rem;
    //             line-height: .36rem;
    //         }
    //         .item-content{
    //             -webkit-line-clamp: 5;
    //         }
    //         .ProductItem{
    //             width: 3.5rem;
    //             height: 6.8rem;
    //         }
    //         .ServiceItem{
    //             width: 6.1rem;
    //             height: 3.85rem;
    //         }
    //         .InsightItem{
    //             width: 9rem;
    //             height: 6rem;
    //         }
    //         .EducationItem{
    //             width: 4rem;
    //             height: 5.2rem;
    //         }
    //     }
    // }
}

.IndexIpad{
    .line{
        width: 1.8rem;
        height: 0.2rem;
        border-radius: 0.12rem;
        margin-top: 14px;
    }
    .Index-banner{
        .banner-item{
            width: 100%;
        }
        .banner-item{
            .img{
                height: auto;
            }
            .banner-label{
                left: 0;
                right: 0;
                text-align: center;
                .banner-title{
                    width: 8rem;
                }
            }
        }
        .slick-dots{
            text-align: center;
            left: 0;
            right: 0;
            li{
                margin: 0 .2rem;
            }
        }
    }
    .index-title{
        font-size: 24px;
    }
    .index-subtitle{
        font-size: .32rem;
        line-height: 24px !important;
    }
    .index-content{
        // font-size: 12px;
        font-size: .3rem;
        line-height: .4rem !important;
    }
    .Index-about{
        .content{
            padding: 0.71rem 1.8rem;
            .index-title{
                margin-bottom: 12px;
            }
            .index-content{
                height: auto;
                font-size: 14px;
                p{
                    line-height: 22px;
                    line-height: 22px;
                }
            }
        }
    }
    .Index-services{
        padding: 0 1rem;
        .content{
            justify-content: space-between;
            width: 21.6rem;
            .ServiceItem{
                // margin: 0;
                width: 10rem;
                height: 5.8rem;
                margin-bottom: 20px;
                box-shadow: 0px 0px 18px -6px #E3E3E3;
                // .index-content{
                //     font-size: 12px;
                //     line-height: 16px;
                // }
                img{
                    width: 60px;
                    height: 60px;
                }
                .item-title{
                    margin: .3rem auto .8rem;
                }
            }
        }
    }
    .Index-contact{
        height: 7.78rem;
        .content{
            .CustomMap{
                max-width: none;
                width: 21rem;
                height: 10rem;
                font-size: 14px;
                line-height: 22px;
                .detail{
                    width: 7rem;
                }
                iframe{
                    width: 14rem;
                }
                img{
                    width: 0.4rem;
                    height: 0.4rem;
                }
            }
        }
    }
    .index-item{
        .item-content{
            font-size: .3rem;
            line-height: .4rem;
        }
    }
    .Index-product{
        padding: 1rem;
        background: #F4F6F7;
        .content{
            width: auto;
        }
        .ProductItem{
            width: 5rem;
            height: 9.4rem;
            .line{
                height: 2px;
            }
            .index-content{
                font-size: .3rem;
                line-height: .4rem !important;
            }
            .index-subtitle{
                font-size: 16px;
                width: 4.3rem;
            }
        }
    }
    .layout-content{
        width: 100%;
        .Index-insight{
            .content{
                justify-content: center;
                .InsightItem{
                    width: 10.8rem;
                    height: 7.2rem;
                    .download{
                        padding-top: 2.7rem;
                    }
                }
                .report-empty-item{
                    background-color: rgba(0, 46, 83, 0.9);
                    color: white;
                    line-height: 7.2rem;
                    text-align: center;
                    font-size: .3rem;
                }
            }
            .view-all{
                padding-right: 2.8rem;
                a{
                    font-size: 12px;
                }
            }
        }
        .Index-education{
            padding: 0 1.8rem;
            .EducationItem{
                width: 4.8rem;
                height: 6rem;
                .item-title{
                    margin-bottom: 10px;
                }
                .item-content{
                    position: static;
                    margin: 0 auto;
                    width: 3.33rem;
                }
            }
        }
        .Index-education{
        .content{
            .EducationItem{
                width: 50%;
                .item-content{
                    width: auto;
                    font-size: .3rem;
                }
            }
        }
    }
    }
    @media (min-width:1000px) and (max-width:1366px) {
        .layout-content{
            // width: 1000px;
            // margin: 0 auto;
            .Index-services{
                padding: 0 1rem;
                .content{
                    .ServiceItem{

                    }
                }
            }
        }
    }
}



.IndexMobile{
    padding-bottom: 4.7rem;
    .layout-content{
        width: 20.9375rem;
    }
    .index-item{
        .content{
            width: auto;
            display: block;
            margin-top: 2.5rem;
            .index-title{
                font-size: 1.75rem;
                line-height: 2.5rem;
            }
        }
    }
    .Index-banner{
        .banner-item{
            padding-top: 1.87rem;
            .img{
                // height: 11.5625rem !important;
            }
            .banner-label{
                .banner-title{
                    font-size: .875rem;
                    line-height: 1.3125rem;
                    letter-spacing: .0625rem;
                }
            }
        }
        .slick-dots{
            bottom: .875rem;
            left: 0;
            right: 0;
            width: 17.1875rem;
            margin: 0 auto;
            >li{
                height: .375rem;
                width: 100%;
                margin: 0;
                margin-right: .875rem;
            }
            .slick-active{
                width: 100% !important;
                height: .375rem;
                margin: 0;
                margin-right: .875rem;
            }
        }
    }
    .Index-product{
        .content{
            .ProductItem{
                width: 16.1875rem;
                margin: 0 auto 1.25rem;
                padding: 2.125rem .8125rem 1.25rem;
                height: 18.125rem;
                box-shadow: 0rem 0rem 1.5rem -0.5rem #E3E3E3;
                .item-header{
                    .img{
                        width: 3.25rem;
                        height: 3.25rem;
                        img{
                            width: 2rem;
                            height: 2rem;
                        }
                    }
                    .index-subtitle{
                        font-size: 1.125rem;
                        width: 14.375rem;
                    }
                    .line{
                        width: 8rem;
                        height: .1875rem;
                        border-radius: .375rem;
                    }
                }
                .item-content{
                    margin-top: 1.125rem;
                    line-height: 1.0625rem;
                    -webkit-line-clamp: 5;
                }
            }
        }
    }
    .Index-about{
        .content{
            padding: 2.875rem 1.625rem;
            margin-top: 0;
            .index-content{
                font-size: .75rem;
                letter-spacing: .0269rem;
                p{
                    line-height: 1.25rem;
    
                }
            }   
        }
    }
    .Index-services{
        margin-top: 4.6875rem;
        .content{
            width: auto;
            .ServiceItem{
                width: 20.375rem;
                height: 13.125rem;
                margin: 1.25rem auto 0;
                padding: 1.625rem .5rem 1.25rem;
                img{
                    width: 3.25rem;
                    height: 3.25rem;
                }
                .item-title{
                    margin: .5rem 0;
                    line-height: 1.5625rem;
                }
                .item-content{
                    line-height: 1.0625rem;
                    font-size: .75rem;
                }
            }
        }
    }
    .Index-insight{
        margin-top: 6.875rem;
        .content{
            width: auto;
            display: block;
            margin-top: .3125rem;
            .InsightItem{
                width: 20.9375rem;
                height: 20.625rem;
                margin: .3125rem auto 0;
                line-height: 20.625rem;
                >p{
                    font-size: 0.75rem;
                }
                .insight-content{
                    padding: 3.875rem 1.125rem 0;
                    .item-title{
                        line-height: 1.5625rem;
                        font-size: .875rem;
                    }
                    .item-content{
                        margin-top: .75rem;
                        line-height: 1.125rem;
                        letter-spacing: .0269rem;
                        -webkit-line-clamp: 8;
                        font-size: .75rem;
                    }
                }
                .download-img{
                    position: absolute;
                    width: 1.875rem;
                    height: 1.875rem;
                    z-index: 4;
                    right: 1.375rem;
                    bottom: 1.375rem;
                }
            }
        }
        .view-all{
            width: auto;
            margin-top: 1.125rem;
            a{
                font-size: .75rem;
                letter-spacing: .0269rem;
                line-height: 1.0625rem;
            }
        }
    }
    .Index-education{
        margin-top: 6.875rem;
        margin-bottom: 4.6875rem;
        .content{
            .EducationItem{
                width: 15.875rem;
                height: 14.25rem;
                margin: 0 auto 1.25rem;
                padding: 3.0625rem .625rem 2.5rem;
                // background-image: url("../../images/img/mobile/img-education-bg.png");
                // background-size: 15.875rem 14.25rem;
                box-shadow: 0rem 0rem 1.5rem -0.5rem #A68576;
                .item-title{
                    line-height: 1.5625rem;
                    font-size: 1.125rem;
                }
                .item-content{
                    top: 6.25rem;
                    line-height: 1.25rem;
                    letter-spacing: .0269rem;
                    width: 14.5625rem;
                    position: static;
                    margin-top: 1.625rem;
                    font-size: .75rem;
                }
            }
        }
    }
    .Index-contact{
        background-color: white;
        height: auto;
        .absolute{
            position: static;
            top: 0;
            .content{
                width: auto;
                .CustomMap{
                    width: auto;
                    flex-direction: column-reverse;
                    height: auto;
                    box-shadow: none;
                    font-size: .875rem;
                    iframe{
                        width: 20.375rem !important;
                        height: 13.125rem !important;
                        margin: 0 auto;
                        border-radius: 1.25rem;
                    }
                    .detail{
                        width: auto;
                        padding: 0;
                        ul{
                            font-size: .75rem;
                            li{
                                margin: 0;
                                line-height: 1.0625rem;
                                margin-bottom: 1.875rem;
                                margin-left: 1.1875rem;
                                img{
                                    width: 1.1875rem;
                                    height: 1.1875rem;
                                }
                                &:first-child{
                                    margin-top: 2.8125rem;
                                }
                            }
                        }
                    }
                }
            }
            position: static;
            width: 21.4375rem;
            background-color: transparent;
            .index-title{
                width: 21.4375rem;
            }
        }
    }
    .index-content{
        font-size: .75rem;
        line-height: 1.0625rem;
        letter-spacing: .0269rem;
    }
    .index-title{
        font-size: 2rem;
        letter-spacing: .0712rem;
        line-height: 2.8125rem;
    }
    .line{
        width: 5rem;
        height: .625rem;
        border-radius: .375rem;
    }
    .index-subtitle{
        font-size: 1.125rem;
        letter-spacing: .04rem;
        line-height: 1.5625rem;
    }
}

.product-modal{

}
.ant-modal-wrap{
    .product-modal{
        margin: 0 auto ;
        width: 11rem !important;
        .product{
            width: 8.7rem;
            min-height: 6rem;
            margin: 0 auto;
            .title{
                text-align: center;
                p{
                    font-size: .26rem;
                    line-height: .28rem;
                }
            }
            .icon{
                width: .98rem;
                height: .98rem;
                margin: .32rem auto .22rem;
                border: .02rem solid #1F1E2E;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 50%;
                    height: 50%;
                    margin: auto;
                }
            }
            .line{
                width: 1.28rem;
                height: .03rem;
                background-color: #A68576;
                border-radius: .03rem;
                margin: .31rem auto .32rem;
            }
            .content{
                p{
                    font-size: .2rem;
                    line-height: .28rem;
                }
            }
        }
    }
    .product-modalIpad{
        width: 20rem !important;
        .product{
            width: 16rem;
            min-height: 11rem;
            .title{
                p{
                    font-size: .4rem;
                    line-height: .5rem;
                }
            }
            .icon{
                width: 1.6rem;
                height: 1.6rem;
            }
            .line{
                width: 2rem;
                height: .1rem;
                border-radius: .05rem;
            }
            .content{
                p{
                    font-size: .3rem;
                    line-height: .5rem;
                }
            }
        }
    }
    .product-modalMobile{
        width: 20rem !important;
        .product{
            width: 16rem;
            min-height: 20rem;
            .icon{
                width: 2.4rem;
                height: 2.4rem;
            }
            .title{
                p{
                    font-size: 1rem;
                    line-height: 1.5rem;
                }
            }
            .line{
                width: 5rem;
                height: .2rem;
                border-radius: .1rem;
                margin: 0 auto 1rem;
            }
            .content{
                p{
                    font-size: .75rem;
                    line-height: 1rem;
                }
            }
        }
    }
}

.statementModal{
    width: 9.78rem !important;
    height: 11rem;
    .ant-modal-content{
        height: 100%;
        padding: .53rem;
        .statement-content{
            .header{
                padding-bottom: .18rem;
                border-bottom: 1px solid #EAEBEC;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .header-title{
                    font-size: .3rem;
                    line-height: .42rem;
                    color: #000;
                    font-weight: 600;
                }
                .header-img{
                    .lang-icon{
                        width: .6rem;
                    }
                    .pull-icon{
                        width: .3rem;
                        height: .3rem;
                        cursor: pointer;
                    }
                    .ant-select{
                        .ant-select-selector{
                            margin-left: .2rem;
                            .ant-select-selection-item,.ant-select-selection-placeholder{
                                display: none;
                            }
                        }
                        .ant-select-dropdown{
                            width: 1rem !important;
                            top: .35rem !important;
                        }
                    }
                }
            }
            .contents{
                height: 8.25rem;
                background-color: #FBFBFB;
                margin-top: .17rem;
                border-radius: .1rem;
                padding: 23px;
                overflow-y: scroll;
                &::-webkit-scrollbar{
                    display: none;
                }
                .content-text{
                    font-size: .2rem;
                    line-height: .28rem;
                    margin-bottom: .2rem;
                }
            }
            .footer{
                padding: .22rem .33rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .Decline{
                    font-size: .22rem;
                    color: #999999;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .Accept{
                    width: 1.8rem;
                    height: .7rem;
                    background-color: #FF0000;
                    color: white;
                    font-size: .22rem;
                    border-radius: .13rem;
                    line-height: .7rem;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
    }
    .ant-modal-close-x{
        display: none !important;
    }
}

.statementModalMobile{
    width: 20.875rem !important;
    height: auto;
    .ant-modal-close{
        display: none;
    }
    .ant-modal-content{
        .statement-content{
            .header{
                .header-title{
                    font-size: 1rem;
                }
                .header-img{
                    .lang-icon{
                        width: 3rem;
                    }
                    .pull-icon{
                        width: 1.5rem;
                        height: 1.5rem;
                    }
                    .ant-select{
                        .ant-select-selector{
                            margin-left: .75rem;
                            
                        }
                        .ant-select-dropdown{
                            width: 5.5rem !important;
                            top:1.9rem !important;
                            font-size: .75rem;
                            .ant-select-item{
                                font-size: .75rem !important;
                            }
                        }
                    }
                }
            }
            .contents{
                height: 25rem;
                overflow-y: scroll;
                
                .content-text{
                    font-size: .75rem;
                    line-height: 1.125rem;
                }
            }
            .footer{
                margin-top: 1rem;
                .Decline,.Accept{
                    font-size: .75rem;
                    line-height: 1.5rem;
                    height: 1.5rem;
                    width: 3rem;
                }
            }
        }
    }
}
