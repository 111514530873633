
@font-face {
    font-family: SourceHanSansCN-Bold;
    src: url('https://ipfs.learnblockchain.cn/SourceHanSansCN-Bold.otf');
}

@font-face {
    font-family: SourceHanSansCN-Normal;
    src: url('https://ipfs.learnblockchain.cn/SourceHanSansCN-Normal.otf');
}
@font-face {
    font-family: SourceHanSansCN-Regular;
    src: url('https://ipfs.learnblockchain.cn/SourceHanSansCN-Regular.otf');
}
@font-face {
    font-family: SourceHanSansCN-Medium;
    src: url('https://ipfs.learnblockchain.cn/SourceHanSansCN-Medium.otf');
}
@font-face {
    font-family: SourceHanSansCN-Heavy;
    src: url('https://ipfs.learnblockchain.cn/SourceHanSansCN-Heavy.otf');
}