.AssetManagementItem {
    width: 7.35rem;
    height: 2.61rem;
    position: relative;
    border: .01rem solid #A58575;
    padding: .35rem .28rem .3rem .35rem;
    border-radius: .26rem;
    margin-bottom: .3rem;
    &:nth-child(2n+1){
        margin-right: .3rem;
    }
    .service-subtitle{
        font-size: .38rem;
        line-height: .38rem;
        letter-spacing: .0136rem;
        text-align: left;
    }
    .desc {
        margin-top: .3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .link {
        position: absolute;
        right: .57rem;
        bottom: .31rem;
        .read-more{
            color: #A68576;
            font-family: SourceHanSansCN-Medium;
        }
        img{
            width: .18rem;
            height: .18rem;
            margin-left: .11rem;
        }
    }
    .sub-line{
        position: absolute;
        top: 1.59rem;
        left: .36rem;
    }
    .num-img{
        width: .89rem;
        height: .4rem;
        img{
            width: 100%;
            height: 100%;
        }
    }
}

.InvestmentManagementItem {
    width: 7.5rem;
    height: 5rem;
    color: white;
    position: relative;
    padding: 1.12rem .46rem .67rem .49rem;
    .content-box{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }
    .title {
        margin-bottom: .19rem;
        font-size: .36rem;
        line-height: .54rem;
        letter-spacing: .0129rem;
        color: #FFD1BF;
    }

    .desc {
        margin-bottom: .1rem;
    }

    .link {
        position: absolute;
        left: .49rem;
        bottom: .67rem;
        color: rgba(246, 206, 190, 1);
        text-decoration: underline;
    }
    .read-more{
        font-size: .18rem;
        line-height: .28rem;
        letter-spacing: .0064rem;
    }

}

.AreaCoverageItem {
    display: flex;
    align-items: center;

    .icon{
        width: .18rem;
        height: .18rem;
        background-color: white;
        border-radius: 50%;
        margin-right: .23rem;
        // border: .01rem solid rgba(166, 133, 118, 1);
        position: relative;
        font-size: 0;
        >img{
            width: 100%;
            height: 100%;
        }
    }
    .icon > div {
        display: block;
        width: .1rem;
        height: .1rem;
        border-radius: 50%;
        background-color: rgba(166, 133, 118, 1);
        position: absolute;
        top: .03rem;
        left: .03rem;
    }

    .title {
        font-size: .26rem;
        letter-spacing: .0093rem;
        line-height: 1rem;    
        font-weight: 400;
    }
}

.BenefitItem {
    width: 5rem;
    height: 5.6rem;
    padding: .61rem .35rem 0 .35rem;
    background-color: #fff;
    box-shadow: 0rem 0rem .24rem -0.08rem #E3E3E3;
    opacity: 0.94;
    transition: background-color 0.3s, color 0.3s;
    position: relative;

    img {
        width: .68rem;
        height: .68rem;
        margin-bottom: .2rem;
    }

    .title {
        height: .66rem;
        margin-bottom: .22rem;
        font-size: .26rem;
        line-height: .39rem;
        letter-spacing: .0093rem;
    }

    .benefitItem-content{
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
    }

    .desc {
        margin-bottom: .1rem;
    }

    .link {
        margin-bottom: .12rem;
        display: block;
        text-decoration: underline;
        color: rgba(1, 114, 200, 1);
    }

    .url {
        width: 1.72rem;
        height: .45rem;
        background-color: #002E53;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: .35rem;
        bottom: .53rem;
        a{
            color: white;
            font-size: .18rem;
            line-height: .25rem;
            letter-spacing: .0064rem;
        }
    }

    &:hover {
        background-color: #002E53;
        color: white;

        .item-header>.img {
            border-color: #fff;
        }

        .link {
            color: rgba(246, 206, 190, 1);
        }

        .url {
            background-color: white;
            a{
                color: #002E53;

            }
        }
    }

    
}