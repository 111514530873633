@mixin contentFont {
    font-size: .75rem;
    line-height: 1.0625rem;
    letter-spacing: .0269rem;
}
@mixin contentSubtitle{
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: .04rem;
}
@mixin titleFont{
    font-size: 1.75rem;
    letter-spacing: .0625rem;
    line-height: 2.5rem;
}


.Services {
    background-color: white;
    padding-bottom: 200px;
    .line {
        width: .8rem;
        height: .1rem;
        margin: 0 auto;
        border-radius: .06rem;
        background-color: #A68576;
    }

    .sub-line {
        width: .46rem;
        height: .04rem;
        border-radius: .02rem;
        background-color: #A68576;
    }
}

.Services-wealth{
    width: 100%;
    height: 10rem;
    background-image: url("../../images/bg/service-bg.png");
    // background-repeat: no-repeat;
    background-size: contain;
    .wealth-content{
        // margin-left: 6.6rem;
        padding-top: 1.43rem;
        display: flex;
        justify-content: center;
        align-items: center;
        .service-title{
            font-size: .4rem;
            line-height: .6rem;
            color: white;
            margin-bottom: .15rem;
        }
        .service-large-title{
            font-size: .6rem;
            line-height: .9rem;
            color: #A68576;
            font-weight: bold;
            margin-bottom: .6rem;
        }
        .service-content{
            color: #FEFEFE;
            font-size: .18rem;
            width: 5rem;
        }
        .learn-btn{
            width: 2.99rem;
            height: .72rem;
            background-color: #fff;
            border-radius: .2rem;
            text-align: center;
            line-height: .72rem;
            font-size: .18rem;
            color: #A68576;
            font-weight: 500;
            margin-top: .6rem;
            display: block;
            img{
                width: .18rem;
                height: .18rem;
                margin-left: .1rem;
            }
        }
        .content-img{
            width: 6.7rem;
            height: 8.02rem;
            margin-left: 1.64rem;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}

.Services-one {
    width: 100%;
    // height: 10rem;
    // background-image: url("../../images/bg/service-bg.png");
    // background-repeat: no-repeat;
    // background-size: contain;

    .content {
        max-width: 21.87rem;
        // height: 100%;
        // display: flex;
        // align-items: center;
        text-align: center;
        margin-top: 1.4rem;
    }

    .content-l {
        flex: 1;
        // height: 8.1rem;
        padding-right: .52rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-self: center;
        color: #2D4F6A;

        .service-content {
            margin-top: .63rem;
            width: 5.86rem;
            margin: .63rem auto 0;
        }
        .service-subtitle-title{
            font-size: .45rem;
        }
    }

    .content-r {
        height: 5.52rem;
        align-self: center;
        background-color: white;
        // padding: 1.11rem 1.19rem 0rem 1.03rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: .9rem auto 0;
    }
}

.Services-two {
    padding-top: 1.2rem;

    .content {
        width: 15rem;
    }

    .content-t {
        display: flex;
        flex-direction: column;
        align-items: center;

        .service-content {
            width: 5.86rem;
            margin: .51rem 0 .9rem 0;
            text-align: center;
        }
    }

    .content-b {
        width: 100%;
        display: flex;

        .InvestmentManagementItem:nth-child(1){
            background-color: rgba(0, 46, 83, 0.49);
        }
        .InvestmentManagementItem:nth-child(2) {
            background-color: rgba(0, 46, 83, 0.74);
        }
    }
}

.Services-three {
    // margin: 1.2rem 0;
    padding: .83rem 0;
    .content {
        width: 15rem;
        // display: flex;
    }

    .content-l {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: .96rem;
        .service-large-title-colorful {
            margin-bottom: .63rem;
        }

        .service-content {
            width: 9.85rem;
            margin: 0 auto;
            text-align: center;
            margin-bottom: .1rem;
        }
    }
    .service-title{
        padding-left: 1.36rem;
        margin-bottom: .36rem;
    }
    .content-list{
        display: flex;
    }
    .content-r {
        flex: 1;
        padding-left: 1.36rem;

        & > p {
            margin-bottom: .28rem;
        }
    }
}

.Services-four {
    padding-top: .55rem;
    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content-t {
        width: 100%;
        padding: .45rem 0 3.61rem 0;
        background-color: rgba(240, 240, 240, 1);
        display: flex;
        flex-direction: column;
        align-items: center;

        .service-large-title-colorful {
            margin-bottom: .53rem;
        }

        .service-content {
            margin-top: .22rem;
            width: 12.98rem;
            text-align: center;
        }
        .service-title{
            font-size: .42rem;
            line-height: .63rem;
            letter-spacing: .0015rem;
        }

    }

    .content-b {
        

        .container {
            width: 15rem;
            display: flex;
            flex-wrap: wrap;       
            position: relative;
            top: -2.8rem;
        }
    }
}

.service-large-title {
    font-size: .6rem;
    font-weight: 500;
    letter-spacing: .02rem;
    line-height: .73rem;
}

.service-subtitle-title{
    font-size: .4rem;
    line-height: .73rem;
    letter-spacing: .0143rem;
}

.service-large-title-colorful {
    font-size: .6rem;
    font-weight: bold;
    letter-spacing: .01rem;
    line-height: .73rem;
    color: #A68576;
}

.service-large-title-white {
    font-size: .6rem;
    font-weight: 500;
    letter-spacing: .02rem;
    line-height: .73rem;
}

.service-title {
    font-size: .32rem;
    font-weight: 500;
    line-height: .45rem;
    letter-spacing: .0114rem;
}

.service-subtitle {
    font-size: .24rem;
    font-weight: 500;
    letter-spacing: .0086rem;
    line-height: .33rem;
}

.service-content {
    font-size: .18rem;
    letter-spacing: .0071rem;
    line-height: .25rem;
}

.ServicesIpad{
    padding-bottom: 200px;
    .service-subtitle-title{
        font-size: .8rem;
        line-height: 1.3rem;
    }
    .service-large-title-colorful{
        font-size: 1.1rem;
        line-height: 1.3rem;
    }
    .Services-wealth{
        width: 100%;
        height: 16rem;
        background-image: url("../../images/bg/service-bg-ipad.png");
        .wealth-content{
            padding-top: 2.3rem;
            .content-text{
                margin-right: 4.825rem;
                .service-title{
                    font-size: .65rem;
                    line-height: .975rem;
                    margin-bottom: .25rem;
                }
                .service-large-title{
                    font-size: .95rem;
                    line-height: 1.45rem;
                    margin-bottom: .9rem;
                }
                .service-content{
                    width: 5.925rem;
                    font-size: .3rem;
                    line-height: .425rem;
                }
                .learn-btn{
                    margin-top: .975rem;
                    width: 4.775rem;
                    height: 1.15rem;
                    line-height: 1.15rem;
                    font-size: .3rem;
                    img{
                        width: .3rem;
                        height: .3rem;
                        margin-left: .1rem;
                    }
                }
            }
            .content-img{
                width: 10.7rem;
                height: 12.8rem;
            }
        }
    }
    .BenefitItem{
        .url{
            a{
                font-size: .3rem;
                line-height: .5rem;
            }
        }
    }  
    .service-content{
        font-size: .3rem;
        line-height: .45rem;
        letter-spacing: .0115rem;
    }
    .Services-one{
        height: 16rem;
        background-size: 100% 100%;
        .content{
            display: block;
            max-width: none;
            padding: 1.8rem .875rem 0;
            .content-l{
                margin-left: 1rem;
                .service-subtitle-title{
                    font-size: .625rem;
                    line-height: 1.15rem;
                }
                .service-content{
                    font-size: .3rem;
                    line-height: .45rem;
                    letter-spacing: .0115rem;
                    width: 9.375rem;
                    margin-top: 1.1rem;
                }
            }
            .content-r{
                // width: 24rem;
                margin-top: 1.375rem;
                .AssetManagementItem{
                    width: 11.75rem;
                    height: 4.175rem;
                    margin-bottom: .5rem;
                    padding: .6rem .625rem;
                    border-radius: .425rem;
                    .num-img{
                        width: 1.5rem;
                        height: .675rem;
                    }
                    .service-subtitle{
                        font-size: .65rem;
                        line-height: .675rem;
                        letter-spacing: .0235rem;
                    }
                    .sub-line{
                        width: .8rem;
                        height: .075rem;
                        top: 2.775rem;
                        left: .625rem;
                    }
                    .link{
                        font-size: .3rem;
                        line-height: .45rem;
                        bottom: .5rem;
                        right: .5rem;
                    }
                }
            }
        }
    }
    .layout-content{
        margin-top: 2.25rem;
        padding: 0 .875rem;
        .service-subtitle-title{
            font-size: .625rem;
            line-height: 1.15rem;
        }
        .service-large-title-colorful{
            font-size: .95rem;
            line-height: 1.15rem;
        }
        .Services-one{
            height: auto;
            .content{
                margin: 0;
                padding: 0;
                .content-r{
                    height: auto;
                }
            }
        }
        .Services-two{
            padding-top: 1.725rem;
            .content{
                width: 100%;
                .content-t{
                    .service-content{
                        width: 9.375rem;
                        margin-top: 1.05rem;
                        margin-bottom: 1.45rem;
                    }
                }
                .content-b{
                    .InvestmentManagementItem{
                        width: 50%;
                        height: 8rem;
                        padding-top: 1.8rem;
                        padding: 1.8rem .75rem 0;
                        .service-subtitle{
                            font-size: .4rem;
                            line-height: .6rem;
                        }
                        .link{
                            left: .75rem;
                            bottom: 1.075rem;
                        }
                    }
                }
            }
        }
        .Services-three{
            .content{
                width: 100%;
                .content-l{
                    .service-content{
                        width: 9.375rem;
                    }
                }
                .service-title{
                    font-size: .5rem;
                    line-height: .8rem;
                }
                .content-r{
                    .AreaCoverageItem{
                        .title{
                            font-size: .4rem;
                            line-height: 1.6rem;
                        }
                        .icon{
                            width: .3rem;
                            height: .3rem;
                        }
                    }
                }
            }
        }
    }
    .Services-four{
        .service-subtitle-title{
            font-size: .625rem;
            line-height: 1.15rem;
        }
        .service-large-title-colorful{
            font-size: .95rem;
            line-height: 1.15rem;
        }
        .content{
            .content-t{
                .service-title{
                    font-size: .5rem;
                    line-height: .75rem;
                }
                .service-content{
                    margin-top: .35rem;
                    width: 16.75rem;
                }
            }
            .content-b{
                .container{
                    width: 24rem;
                    margin: 0 auto;
                    .BenefitItem{
                        width: 8rem;
                        height: 9rem;
                        padding: 1rem .55rem;
                        img{
                            width: 1.1rem;
                            height: 1.1rem;
                        }
                        .service-subtitle{
                            font-size: .4rem;
                            line-height: .6rem;
                            margin-bottom: .875rem;
                        }
                        .url{
                            width: 2.75rem;
                            height: .7rem;
                            left: .55rem;
                            bottom: .85rem;
                        }
                    }
                }
            }
        }
    }
    .Services-two{
        .content-b{
            .InvestmentManagementItem{
                .read-more{
                    font-size: .3rem;
                    line-height: .5rem;
                }
            }
        }
    }
}



.ServicesMobile{
    .line{
        width: 5rem;
        height: .625rem;
        border-radius: .375rem;
        margin-top: .5625rem;
    }
    .sub-line{
        width: 2.875rem;
        height: .25rem;
    }
    .service-subtitle-title{
        font-size: 1rem;
    }
    .services-mobile{
        width: 100%;
        padding-top: 0;
        .content-t{
            .service-content{
                width: 100%;
            }
            .service-large-title{
                @include titleFont();
            }
        }
        .content-l{
            .service-large-title{
                @include titleFont();
            }
        }
        
    }
    .Services-wealth{
        padding-top: 3.9375rem;
        width: 100%;
        height: 50.625rem;
        background-image: url("../../images/bg/service-bg-mobile.png");
        background-size: cover;
        .wealth-content{
            display: block;
            padding: 0 1.3125rem;
            .content-text{
                .service-title{
                    font-size: 1.25rem;
                    line-height: 1.875rem;
                    margin-bottom: .5rem;
                }
                .service-large-title{
                    @include titleFont();
                    margin-bottom: 1.125rem;
                }
                .service-content{
                    font-size: .75rem;
                    line-height: 1.0625rem;
                    width: 14.8125rem;
                }
                .learn-btn{
                    width: 11.9375rem;
                    height: 2.875rem;
                    border-radius: .8125rem;
                    font-size: .75rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 1.5625rem;
                    margin-bottom: 3.1875rem;
                    img{
                        width: .75rem;
                        height: .75rem;
                        margin-left: .25rem;
                    }
                }
            }
            .content-img{
                width: 20.75rem;
                height: 24.8125rem;
                margin: 0 auto;
            }
        }
    }
    .Services-one{
        // background-image: url(../../images/bg/bg-banner-services-mobile.png);
        // background-size: cover;
        // height: 50.6875rem;
        // position: relative;
        .content{
            width: 21.4375rem;
            display: block;
            margin-top: 4.1875rem;
            .content-l{
                padding: 0;
                display: block;
                .service-subtitle-title{
                    font-size: 1rem;
                    line-height: 2.5rem;
                }
                .service-large-title{
                    font-size: 1.75rem;
                    letter-spacing: .0625rem;
                    line-height: 2.5rem;
                }
                .service-large-title-colorful{
                    margin-bottom: .75rem;
                }
                .service-content{
                    width: 100%;
                    margin-top: 0;
                    @include contentFont();
                    
                }
            }
            .content-r{
                width: 20.875rem;
                padding: 0;
                // position: absolute;
                // top: 17.1875rem;
                // left: calc((100% - 20.9375rem) / 2);
                // box-shadow: 0rem 0rem 1.5rem -0.5rem #D6D6D6;
                padding-top: 2.375rem;
                height: auto;
                justify-content: center;
                margin: 0;
                .AssetManagementItem{
                    width: 100%;
                    height: 7.4375rem;
                    padding: 1.75rem;
                    margin: 0;
                    margin-bottom: 1.25rem;
                    .service-subtitle{
                        @include contentSubtitle();
                    }
                    .service-content{
                        @include contentFont();
                    }
                    .desc{
                        margin-top: .75rem;
                        margin-bottom: .125rem;
                    }
                    .link{
                        left: auto;
                        right: 1.25rem;
                        bottom: .625rem;
                        img{
                            width: .5625rem;
                            height: .5625rem;
                        }
                    }
                    .num-img{
                        width: 2.8125rem;
                        height: 1.25rem;
                    }
                    .sub-line{
                        top: auto;
                        left: 1.85rem;
                    }
                }
            }
        }
    }
    .Services-two{
        margin-top: 4.6875rem;
        .content{
            width: 100%;
            .content-t{
                margin-bottom: 1.875rem;
                .service-content{
                    @include contentFont();
                    width: 21rem;
                    margin: 0 auto;
                    margin-top: .75rem;
                }
            }
            .content-b{
                display: block;
                width: 20.9375rem;
                margin: 0 auto;
                .InvestmentManagementItem{
                    width: 100%;
                    padding: 2.5625rem .9375rem 4.125rem;
                    height: 21.0625rem;
                    .service-subtitle{
                        @include contentSubtitle();
                        margin-bottom: .9375rem;
                    }
                    .desc{
                        @include contentFont();
                    }
                    .link{
                        left: auto;
                        bottom: 1.5rem;
                        right: .9375rem;
                        .read-more{
                            font-size: 0.75rem;
                            line-height: 1.0625rem;
                        }
                    }
                }
            }
        }
    }
    .Services-three{
        margin: 4.6875rem 0;
        .content{
            display: block;
            width: 21rem;
            .content-l{
                text-align: center  ;
                .service-content{
                    width: 100%;
                    @include contentFont();
                }
                .service-large-title-colorful{
                    margin-bottom: .75rem;
                }
            }
            .content-list{
                display: block;
            }
            .service-title{
                font-size: 1.125rem;
                line-height: 2.5rem;
                letter-spacing: .04rem;
                text-align: center;
                margin-bottom: 0;
            }
            .content-r{
                padding: 0;
                margin-top: 1.375rem;
                &:nth-of-type(2) {
                    margin-top: 0;
                }
                
                .AreaCoverageItem{
                    padding-left: 1.875rem;
                    .title{
                        font-size: .875rem;
                        line-height: 3.4375rem;
                        letter-spacing: .0313rem;
                    }
                    .icon{
                        margin-right: .9375rem;
                        width: 1.125rem;
                        height: 1.125rem;
                    }
                    width: 100%;
                }
            }
        }
    }
    .Services-four{
        position: relative;
        .content{
            .content-t{
                padding-top: 3.4375rem;
                padding-bottom: 13.0625rem;
                margin-bottom: 121.875rem;
                .service-title{
                    @include contentSubtitle();
                }
                .line{
                    margin-bottom: 1.25rem;
                }
                .service-content{
                    width: 21rem;
                    margin: 0 auto .3125rem;
                    @include contentFont();
                }
            }
            .content-b{
                width: 20.9375rem;
                position: absolute;
                top: 28.6875rem;
                
                .container{
                    width: 100%;
                    position: static;
                    display: block ;
                    .BenefitItem{
                        padding: 1.9375rem .9375rem 0; 
                        width: 100%;
                        height: 20.9375rem;
                        background-color: #002E53;
                        color: white;
                        border: .0625rem solid #F0F0F0;
                        box-shadow: none;
                        img{
                            width: 3.125rem;
                            height: 3.125rem;
                            margin-bottom: .9375rem;
                        }
                        .title{
                            height: auto;
                            margin-bottom: .75rem;
                        }
                        .service-subtitle{
                            @include contentSubtitle();
                        }
                        .benefitItem-content{
                            -webkit-line-clamp: 4;
                            .desc{
                                @include contentFont();
                                margin-bottom: .3125rem;
                                -webkit-line-clamp: 4;
                            }
                        }
                        .url{
                            left: auto;
                            right: 2rem;
                            bottom: 1.9375rem;
                            width: 8.375rem;
                            height: 2.1875rem;
                            background-color: white;
                            a{
                                color: #002E53;
                                @include contentFont();
                                line-height: 2.1875rem;
                            }
                        }
                        .link{
                            @include contentFont();
                            margin-bottom: .1875rem;
                        }
                    }
                }
            }
        }
    }
}

