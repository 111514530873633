.ReportItem {
    width:  7.3rem;
    height: 4rem;
    position: relative;
    cursor: pointer;
    margin-bottom: .33rem;
    overflow: hidden;
    .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
    }

    .content-container {
        background: linear-gradient(270deg, rgba(0,41,65,0.3) 0%, #0F385A 100%);
        color: white;
        padding: .58rem .46rem 0 .49rem;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
        .f32{
            font-size: .32rem;
            line-height: .48rem;
        }
        .f20{
            font-size: .2rem;
            line-height: .28rem;
            letter-spacing: .0071rem;
        }
        .title {
            width: 4.8rem;
            line-height: .37rem;
            margin-bottom: .21rem;
            // font-size: 800;
        }
        .researchTitle{
            font-size: .32rem;
            font-weight: 500;
            line-height: .48rem;
        }
        .researchContent{
            font-size: .20rem;
            font-weight: 400;
            letter-spacing: .0071rem;
            line-height: .26rem;
        }
    }

    .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 3;
        transition: background-color 0.3s, color 0.3s;
        color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            visibility: hidden;
        }

        &:hover {
            background-color: rgba(#A68576, 0.9);
            color: white;
            img {
                visibility: visible;
            }
        }
    }
}

.InvestorEducationItem {
    display: flex;
    align-items: center;

    .content-container {
        padding-left: .8rem;
        margin: .5rem 0;


        .insight-content {
            width: 6.72rem;
            margin-top: .07rem;
        }
    }

}