@mixin contentFont {
    font-size: .75rem;
    line-height: 1.0625rem;
    letter-spacing: .0269rem;
}
@mixin contentSubtitle{
    font-size: 1.125rem;
    line-height: 1.5625rem;
    letter-spacing: .04rem;
}




.Header{
    width: 100%;
    height: auto !important;
    padding: 0 !important;
    position: absolute;
    z-index: 999;
    // display: none;
    font-family: SourceHanSansCN-Regular !important;
    .container{
        width: 15rem;
        height: .88rem;
        margin: 0 auto;

        display: flex;
        justify-content: space-between;
        align-items: center;

        font-size: .18rem;
        letter-spacing: .0064rem;
        .ant-dropdown{
            .ant-dropdown-menu{
                display: block;
                padding: .05rem .25rem;
                // width: 2.7rem;
                // height: 2.5rem;
                margin-top: .26rem;
                .ant-dropdown-menu-item{
                    border-bottom: .005rem solid #A68576;
                    border-radius: 0;
                    padding: .15rem 0rem;
                    margin-right: 0;
                    &:hover{
                        background-color: white;
                    }
                    &:last-child{
                        border: none;
                    }
                    .ant-dropdown-menu-title-content{
                        font-size: .18rem;
                        color: #1F1E2E;
                        &:hover{
                            color: #A68576;
                        }
                    }
                }
            }
        }
        .logo{
            width: 1.5rem;
            height: .5rem;
            position: relative;
        }
        .list, ul{
            display: flex;
            align-items: center;
        }
        .list > ul{
            li{
                margin-right: .77rem;
                color: white;
                // &:last-child>a{
                //         background-color: white;
                //         color: #002E53;
                //         padding: .02rem .16rem;
                // }
                &:last-child .username{
                    cursor: pointer;
                }
                .ant-dropdown-trigger{
                    &:hover{
                        text-decoration: underline; 
                    }
                }
            }
        }
        .icon{
            // width: .16rem;
            // height: .16rem;
            // position: relative;
            // cursor: pointer;
            margin-top: -0.05rem;
            .ant-select{
                font-size: .14rem;
                .ant-select-selector{
                    background-color: transparent;
                    color: white;
                }
                .ant-select-arrow{
                    color: white;
                }
                &:hover{

                    border-color: white;
                }
                .ant-select-dropdown{
                    width: 2.6rem !important;
                    padding: .1rem .25rem .25rem;
                    .rc-virtual-list{
                        // width: 2.6rem;
                        .rc-virtual-list-holder-inner{
                            .ant-select-item{
                                border-bottom: .01rem solid #A68576;
                                border-radius: 0;
                                padding: .15rem 0;
                                font-size: .18rem;
                                margin-bottom: .1rem;
                                &:last-child{
                                    border: none;
                                }
                                &:hover{
                                    color: #A68576;
                                    background-color: #FAF9F8;
                                }
                            }
                            .ant-select-item-option-selected{
                                background-color: white;
                            }
                        }
                    }
                }
            }
            .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
                border-color: rgba($color: #fff, $alpha: .8);
            }
            .ant-select-focused .ant-select-selector,
            .ant-select-selector:focus,
            .ant-select-selector:active{
                border-color: rgba($color: #fff, $alpha: .8) !important;
                box-shadow: none !important;
            }
            .ant-select-focused{
                .ant-select-selector{
                    background-color: transparent;
                    color: white;
                    .ant-select-selection-item{
                        color: white;
                    }
                }
                .ant-select-arrow{
                    color: white;
                }
            }
            
        }
        a{
            color: white;
        }
    }
    // @media (min-width:1200px) and (max-width:1500px) {
    //     .container{
    //         width: 1200px;
    //     }
    // }
    // @media (min-width:1000px) and (max-width:1199px) {
    //     .container{
    //         width: 1000px;
    //     }
    // }
    // @media (min-width:766px) and (max-width:999px) {
    //     .container{
    //         width: 700px;
    //     }
    // }

    .containerIpad{
        width: 100%;
        height: 1.8rem;
        font-size: 18px;
        padding: 0 1rem;
        .logo{
            width: 3rem;
            height: 1rem;
        }
        .ant-dropdown{
            display: none;
        }
        .icon{
            .ant-select{
                .ant-select-arrow{
                    right: .2rem;
                }
                .ant-select-dropdown{
                    .rc-virtual-list{
                        .rc-virtual-list-holder{
                            .rc-virtual-list-holder-inner{
                                .ant-select-item{
                                    font-size: .4rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .containerMobile{
        width: 100%;
        max-width: 93.75rem;
        height: 2.75rem;
        position: relative;
        .logo{
            height: 2.75rem;

        }
        .selAndIcon{
            display: flex;
            justify-content: end;
            align-items: center;
            width: 10rem;
            .icon{
                flex: 2;
                padding-left: 0;
                line-height: 2.75rem;
            }
            .menuIcon{
                text-align: right;
                flex: 1;
            }
        }
        .fillImg{
            width: 4.5rem;
            height: 1.5rem;
            margin-top: .625rem;
            margin-left: 1.6875rem;
        }
        .menuIcon{
            height: 2.75rem;
            line-height: 2.75rem;
            img{
                height: 1rem;
                width: auto;
                margin-right: 1.625rem;
            }
            .close{
                width: 1.2rem;
                height: 1.2rem;
            }
        }
        .list{
            display: block;
            ul{
                position: absolute;
                left: 0;
                top: 2.75rem;
                background-color: white;
                display: block;
                width: 100%;
                overflow: hidden;
                height: 0;
                transition: height 0.2s;
                li{
                    color: #1F1E2E;
                    border-bottom: .0625rem solid #E9EDF0;
                    margin-right: 0;
                    position: relative;
                    .menu-title{
                        padding-left: 1.5rem;
                    }
                    a{
                        color: #1F1E2E;
                    }
                    img{
                        width: 1rem;
                        height: 1rem;
                        position: absolute;
                        right: 1.625rem;
                        top: 1.5625rem;
                    }
                    .down-menu{
                        background-color: #F8F8F8;
                        padding: 0 6.625rem 0 2.8125rem;
                        max-height: 0;
                        overflow: hidden;
                        transition: max-height 0.2s linear;
                        .down-menu-item{
                            border-bottom: .0625rem solid #A68576;
                            transition: height 0.2s;
                            height: 3.4375rem;
                            line-height: 3.4375rem;
                            &:last-child{
                                border-bottom: none;
                            }
                            a{
                                @include contentSubtitle();
                            }
                        }
                    }
                    .down-menu-open{
                        max-height: 17.625rem;
                        .down-menu-item{
                            height: 3.4375rem;
                        }
                    }
                }
                .AMC-li{
                    padding-left: 1.5rem;
                    font-size: 1rem;
                    line-height: 4rem;
                }
                .user{
                    border-bottom: none;
                    margin-right: 0;
                    width: 15.3125rem;
                    height: 3.125rem;
                    border: .0625rem solid #274E6D;
                    text-align: center;
                    line-height: 3.125rem;
                    font-size: 1rem;
                    margin: 4.125rem auto 0;
                }
                .login{
                }
                .userName{
                    .username{
                        display: inline-block;
                        width: 15.3125rem;
                        height: 3.125rem;
                    }
                }
            }
            .openMenu{
                height: calc(100vh - 2.75rem);
                .menu-title{
                    line-height: 4rem;
                    font-size: 1rem;
                }
            }
        }
        .icon{
            height: 2.75rem;
            line-height: 2.5rem;
            margin-right: -1.25rem;
            padding-left: 2.5rem;
            margin-top: 0;
            .ant-select{
                .ant-select-selector{
                    width: 5rem;
                    height: 1.5rem;
                    padding: 0 .3rem;
                    .ant-select-selection-item{
                        font-size: .625rem;
                        line-height: 1.375rem;
                        letter-spacing: .0238rem;
                    }
                }
                .ant-select-arrow{
                    font-size: .625rem;
                    top: 52%;
                    right: 10%;
                }
                .ant-select-dropdown{
                    width: 5rem !important;
                    padding: 0;
                    .rc-virtual-list{
                        .rc-virtual-list-holder{
                            .rc-virtual-list-holder-inner{
                                .ant-select-item{
                                    font-size: .625rem;
                                    text-align: center;
                                    padding: .625rem;
                                    margin-bottom: .3125rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.ant-layout{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .ant-layout-header{
        background-color: rgb(0, 80, 124);
    }
    .ant-layout-content{
        font-family: SourceHanSansCN-Normal !important;
        background-color: white;
        flex-grow: 1;
        // padding-top: 0.88rem;
        h5{
            font-family: SourceHanSansCN-Blod !important;
        }
        .read-more{
            font-family: SourceHanSansCN-Regular;
        }
        // .index-subtitle{
        //     font-family: SourceHanSansCN-Bold !important;
        // }
        .fontBlod{
            font-family: SourceHanSansCN-Bold !important;
        }
        .fontMedium{
            font-family: SourceHanSansCN-Medium;
        }
        .fontHeavy{
            font-family: SourceHanSansCN-Heavy;
        }
        .fontRegular{
            font-family: SourceHanSansCN-Regular;
        }
        .fontNormal{
            font-family: SourceHanSansCN-Normal;
        }
    }

    .contentIpad{
        padding-top: 1.8rem;
    }

    .contentMobile{
        padding-top: 2.75rem;
    }
    
    .Header-fix{
        background-color: rgb(0, 80, 124);
    }
    .Header-noBanner{
        background-color: rgb(0, 80, 124);
    }
}

.Header-fix{
    position: fixed;
}

.ant-dropdown{
    .ant-dropdown-menu{
        padding: .05rem .25rem;
        // width: 2.7rem;
        // height: 2.5rem;
        margin-top: .26rem;
        .ant-dropdown-menu-item{
            border-bottom: .005rem solid #A68576;
            border-radius: 0;
            padding: .15rem 0rem;
            &:hover{
                background-color: white;
            }
            &:last-child{
                border: none;
            }
            .ant-dropdown-menu-title-content{
                font-size: .18rem;
                color: #1F1E2E;
                &:hover{
                    color: #A68576;
                }
            }
        }
    }
}

.Footer{
    width: 100%;
    height: 4.18rem;
    background: url("../images/bg/bg-footer.png") no-repeat center !important;
    background-size: cover !important;
    position: relative;
    // display: none;
    font-family: SourceHanSansCN-Normal !important;
    .fontBlod{
        font-family: SourceHanSansCN-Blod !important;
    }
    .container{
        width: 11.19rem; 
        height: 100%;
        margin: 0 auto;
        
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        .content{
            width: 100%;
            display: flex;
            .footer-logo{
                width: .92rem;
                height: .92rem;
                margin-right: 1.9rem;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .footer-content{
                display: flex;
                text-align: left;
                justify-content: space-between;
                font-size: .14rem;
                width: 8.37rem;
                .content-item{
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    .item-title{
                        font-size: .14rem;
                        line-height: .2rem;
                        font-weight: 600;
                        margin-bottom: .12rem;
                    }
                    li{
                        margin-bottom: .04rem;
                        a{
                            font-size: .14rem;
                            line-height: .2rem;
                        }
                    }
                }
            }
            .subtitle{
                img{
                    width: 16px;
                    height: 16px;
                    margin-right: .06rem;
                }
                a{
                    color: white;
                    margin-bottom: .04rem;
                    line-height: .2rem;
                }
                .wechatCode{
                    img{
                        width: 124px;
                        height: 124px;
                    }
                    p{
                        color: #8E9FB7;
                        font-size: .14rem;
                    }
                }
            }
        }
    }
    .title{
        font-size: .4rem;
        font-weight: 500;
        line-height: .56rem;
        letter-spacing: .0143rem;
    }
    // .subtitle{
    //     margin-top: .2rem;

    //     font-size: .2rem;
    //     line-height: .28rem;
    //     letter-spacing: .0071rem;

    //     display: flex;
    //     flex-direction: column;
    //     justify-content: center;
    //     align-items: center;
        
    //     &>img, &>p{
    //         margin-right: .13rem;
    //     }
    //     a{
    //         margin-right: .2rem;

    //     }
    //     img{
    //         width: .2rem;
    //         height: .2rem;
    //         &:last-child{
    //             margin-right: 0;
    //             width: .21rem;
    //         }
    //     }
    //     .wechat{
    //         img{
    //             width: .21rem;
    //             height: .21rem;

    //         }
    //     }
    //     .wechatCode{
    //         img{
    //             width: 124px;
    //             height: 124px;
    //         }
    //         p{
    //             color: #8E9FB7;
    //             font-size: .14rem;
    //         }
    //     }
    //     .ant-dropdown{
    //         .ant-dropdown-menu{
    //             padding: .03rem;
    //             .ant-dropdown-menu-item{
    //                 padding: 0;
    //             }
    //         }
    //     } 
    // }
    .line{
        width: 100%;
        margin: .2rem auto .46rem;
        background-color: white;
    }
    ul{
        // display: flex;
        // justify-content: center;

        line-height: .2rem;
        letter-spacing: .005rem;
        .box{
            min-width: .15rem;
            margin: 0 .1rem;
        }
        .l{
            margin: 0;
            background-color: white;
        }
        a{
            color: white;
        }
    }
    .copyright{
        line-height: .2rem;
        letter-spacing: .005rem;
        width: 100%;
        position: absolute;
        bottom: .22rem;
        left: 0;
    }
    .containerIpad{
        width: 18rem;
        .content{
            width: 100%;
            .footer-logo{
                width: 1.5rem;
                height: 1.5rem;
            }
            .footer-content{
                width: 14.37rem;
                .content-item{
                    .item-title{
                        font-size: .3rem;
                        line-height: .45rem;
                    }
                    li{
                        a{
                            font-size: .3rem;
                        line-height: .45rem;

                        }
                    }
                }
            }
            .title{
                font-size: .7rem;
                line-height: 1.05rem;
            }
            .subtitle{
                font-size: .3rem;
                line-height: .45rem;
                img{
                    width: .3rem;
                    height: .3rem;
                }
                a{
                    line-height: .45rem;
                }
                .wechatCode{
                    img{
                        width: 5rem;
                        height: 5rem;
                    }
                }
                // .ant-dropdown{
                //     .ant-dropdown-menu{
                //         padding: .03rem;
                //         .ant-dropdown-menu-item{
                //             padding: 0;
                //         }
                //     }
                // } 
            }
            .copyright{
                width: 100%;
                text-align: center;
                bottom: 1.45rem;
                font-size: .3rem;
            }
        }
    }
}

.FooterIpad{
    height: 7.5rem;
}

.FooterMobile{
    padding: 4.1875rem 3.0625rem 2.25rem !important;
    height: 61.375rem;
    .containerMobile{
        width: 100%;
        align-items: flex-start;
        text-align: left;
        height: auto;
        .content{
            flex-direction: column;
            .footer-logo{
                width: 5.5rem;
                height: 5.6875rem;
                margin-bottom: 2.25rem;
            }
            .title{
                font-size: 1.75rem;
                letter-spacing: .0625rem;
                line-height: 2.5rem;
            }
            .footer-content{
                flex-direction: column;
                font-size: .875rem;
                line-height: 1.25rem;
                letter-spacing: .0256rem;
                padding-left: .5rem;
                .content-item{
                    flex-direction: column;
                    margin-bottom: 2.5rem;
                    .item-title{
                        font-size: .875rem;
                        line-height: 1.25rem;
                        margin-bottom: .75rem;
                    }
                    li{
                        a{
                            font-size: .875rem;
                            line-height: 1.25rem;
                            margin-bottom: .25rem;
                        }
                    }
                }
            }
            .subtitle{
                @include contentFont();
                justify-content: start;
                img{
                    width: 1rem;
                    height: 1rem;
                }
                .wechatCode{
                    img{
                        width: 10rem;
                        height: 10rem;
                    }
                }
                .ant-dropdown{
                    .ant-dropdown-menu{
                        padding: .2rem;
                        .ant-dropdown-menu-item{
                            padding-top: 0;
                        }
                    }
                } 
                a{
                    font-size: .875rem;
                    line-height: 1.25rem;
                    display: flex;
                    margin-bottom: .25rem;
                    img{
                        margin-right: .375rem;
                    }
                    
                }
            }
            .copyright{
                width: 100%;
                @include contentFont();
                bottom: 0;
                height: 3.75rem;
                font-size: .875rem;
                line-height: 3.75rem;
                text-align: center;
                background-color: #002E53;
            }
            .line{
                margin: 1.4375rem 0;
            }
            >ul{
                flex-direction: column;
                li{
                    margin-bottom: 1.25rem;
                    font-size: .75rem;
                }
            }
        }
    }
}



.pageMobile{
    .layout-content{
        width: 21.4375rem;
        margin: 0 auto;
    }
}